// WorkItemPrintCommand.tsx

import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { WorkItem } from "../../../../../shared/models/workItem.model";
import PrintableWorkItemSummaryPage from "./PrintableWorkItemsSummaryPage";
import PrintableWorkItemPage from "./PrinatableWorkItemPage";

interface PrintComponentProps {
  items: WorkItem[];
  onClose: () => void;
}

const WorkItemPrintCommand = forwardRef(({ items, onClose }: PrintComponentProps, ref) => {
  const printContentRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    handlePrint: async () => {
      if (!printContentRef.current) {
        console.error("Print content not found");
        return;
      }

      try {
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        if (!printWindow) {
          console.error("Unable to open print window");
          return;
        }

        const styles = Array.from(document.styleSheets)
          .map((styleSheet) => {
            try {
              return Array.from(styleSheet.cssRules)
                .map((rule) => rule.cssText)
                .join('\n');
            } catch (e) {
              console.warn("Could not load some styles:", e);
              return '';
            }
          })
          .join('\n');

        printWindow.document.write(`
          <html>
          <head>
            <title>Print Work Items</title>
            <style>${styles}</style>
          </head>
          <body>${printContentRef.current.innerHTML}</body>
          </html>
        `);

        printWindow.document.close();

        printWindow.onload = () => {
          setTimeout(() => {
            printWindow.focus();
            printWindow.print();
            printWindow.onafterprint = () => {
              printWindow.close();
              onClose();
            };
          }, 500);
        };
      } catch (error) {
        console.error("Error during printing:", error);
      }
    },
  }));

  return (
    <div ref={printContentRef} style={{ display: 'none' }}>
      <PrintableWorkItemSummaryPage items={items} />
      {items.map((item) => (
        <PrintableWorkItemPage key={item.id} item={item} />
      ))}
    </div>
  );
});

export default WorkItemPrintCommand;


