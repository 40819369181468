import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Box, Toolbar, IconButton, AppBar } from "@mui/material";
import { Employee } from "../../../../shared/models/employee.model";
import useAppStore from "../../../../appStore";
import RelationshipAssignees from "./assignees/RelationshipAssignees";
import RelationshipActionTimeline from "./action-items/RelationshipActionTimeline";
import SubHeader from "../../../../shared/components/page/page-header/SubHeader";
import ContactSelect from "./contact/ContactSelect";
import { SalesContact } from "../../../../shared/models/salesContact.model";
import { AppDate } from "../../../../shared/utils/date-time/AppDate";


const RelationshipForm = ({ onSave, onClose, itemToEdit }) => {
  const {loggedInUser, whiteLabelProps} = useAppStore();
  const [description, setDescription] = useState(itemToEdit.description);
  const [questions, setQuestions] = useState(itemToEdit.questions_to_ask);
  const [whatToConvey, setWhatToConvey] = useState(itemToEdit.what_to_convey);
  const [assignedTo, setAssignedTo] = useState<Employee[]>(itemToEdit.assigned_to_employees.length > 0 ? itemToEdit.assigned_to_employees : [loggedInUser?.employee]);
  const [contact, setContact] = useState<SalesContact>(itemToEdit.sales_contact);

  const handleSave = () => {
    const updated = {
      ...itemToEdit,
      create_date: itemToEdit.id ? itemToEdit.create_date : new AppDate().dateTime,
      sales_contact: contact,
      provider: loggedInUser?.organization,
      description: description,
      assigned_to_employees: assignedTo,
      questions_to_ask: questions,
      what_to_convey: whatToConvey,
    };

    onSave(updated);
  };


  return (
    <div>

      <div style={{marginBottom: '10px'}}>

        <Box sx={{
          color: "black",
          border: '1px solid #c7c7c7',
          padding: "15px 10px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: 1
        }}>
            <ContactSelect contact={contact} setContact={setContact} item={itemToEdit} />
        </Box>


        <Box className="d-flex flex-column mt-6 mb-6" sx={{ borderRadius: '5px', justifyContent: 'space-between', border: '1px solid #c7c7c7', margin: 1 }}>
          <Box className="d-flex flex-row" sx={{ justifyContent: 'space-between', padding: '10px' }}>
            <SubHeader title={'Overview'} showAction={false} />
          </Box>

          <Box className="p-4 d-flex flex-column">
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#777'}}>Description</Typography>
            <TextField value={description} onChange={(e) => setDescription(e.target.value)} fullWidth multiline rows={4} sx={{ marginBottom: "20px" }} />

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#777', marginTop: '10px'}}>What to Convey</Typography>
            <TextField value={whatToConvey} onChange={(e) => setWhatToConvey(e.target.value)} fullWidth multiline rows={4} sx={{ marginBottom: "20px" }} />

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#777', marginTop: '10px'}}>Questions to Ask</Typography>
            <TextField value={questions} onChange={(e) => setQuestions(e.target.value)} fullWidth multiline rows={4} sx={{ marginBottom: "20px" }} />
          </Box>
        </Box>

        {itemToEdit?.id !== 0 && (
          <RelationshipActionTimeline relationship={itemToEdit}/>
        )}

        <Box className="d-flex flex-column mt-6" sx={{ borderRadius: '5px', justifyContent: 'space-between', border: '1px solid #c7c7c7', margin: 1 }}>
          <div style={{padding: '10px'}}>
            <SubHeader title={'Assigned To'} showAction={false} />
          </div>
          <Box className="p-4 d-flex flex-column">
            <RelationshipAssignees assignedTo={assignedTo} setAssignedTo={setAssignedTo} />
          </Box>
        </Box>

      </div>

    </div>
  );
};

export default RelationshipForm;