import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import useCasesStore from "../../../../caseStore";
import useAppStore from "../../../../../../appStore";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";

const BaseCaseDetails = () => {

  const {loggedInUser} = useAppStore();
  const {caseToEdit, setCaseToEdit} = useCasesStore();
  const [media, setMedia] = useState<Media[]>(caseToEdit.media);


  useEffect(() => {
    setCaseToEdit(prev => {
      const updated = {
        ...prev,
        media: media
      };
      return updated;
    });

  }, [media]);

  const handleDescChange = (event) => {
    setCaseToEdit(prev => ({ ...prev, description: event.target.value }));
  };

  const handleLocationChange = (event) => {
    const selected = loggedInUser?.permissions.locations.find(location => location.id === event.target.value);
    if (selected) {
      setCaseToEdit(prev => ({ ...prev, location: selected }));
    }
  };

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Location</span>
        <Select
          value={caseToEdit.location?.id || ''}
          onChange={handleLocationChange}
        >
          {loggedInUser?.permissions.locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ marginBottom: 15 }}>
        <span className="text-gray-700">Description</span>
        <TextField
          name="Description"
          value={caseToEdit.description}
          onChange={handleDescChange}
          fullWidth
          multiline
          rows={5} />
      </div>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <span className="text-gray-700">Attachments</span>
        <PhotoUploaderViewer
          item={caseToEdit}
          setMedia={setMedia}
          captions={caseToEdit.status === "Closed"}
          module={"cases"}
        />
      </div>
    </>

  );
};

export default BaseCaseDetails;
