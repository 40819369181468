import { WorkItem } from "../../../../../shared/models/workItem.model";
import React from "react";
import {
  calculateProgress, formatDate,
  getBuildingName, getEmployee, getEntityTypeName,
  getFloor
} from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import useAppStore from "../../../../../appStore";
import { Chip } from "@mui/material";
import ProgressBar
  from "../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";

type PrintableWorkItemRowProps = {
  item: WorkItem;
  onSelect: (item: WorkItem) => void;
  isSelected: boolean;
  condensed: boolean;
};

const PrintableWorkItemRow: React.FC<PrintableWorkItemRowProps> = ({ item, onSelect, isSelected, condensed=false }) => {
  const { value, colorClass, label } = calculateProgress(item.urgency);
  const { whiteLabelProps, appImages } = useAppStore();

  return (
    <tr>
      {/* Checkbox Column */}
      {condensed ? (
        <td style={{ paddingRight: '10px', width: '2%' }}>
          <img src={appImages.workItemRow} style={{width: '50px', height: '50px', marginBottom: '15px'}} />
        </td>
        ) : (
        <td style={{ paddingRight: '10px', width: '2%' }}>
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <input
              className="form-check-input widget-9-check"
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(item)}
              style={{
                appearance: 'none',
                width: '25px',
                height: '25px',
                backgroundColor: isSelected ? whiteLabelProps.primary_color_light : 'transparent',
                border: '2px solid #ccc',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            />
          </div>
        </td>
      )}


      {/* Where Column */}
      <td style={{ paddingLeft: '0', width: '25%' }}>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <span className="fw-bold">{getBuildingName(item)}</span>
            <span className="text-muted fst-italic">
              {getFloor(item)} / {getEntityTypeName(item)}
            </span>
          </div>
        </div>
      </td>

      {/* Who Column */}
      <td style={{ paddingLeft: '10px', width: '25%' }}>
        <div className="fs-7 d-flex flex-column">
          <div>
            <span className="text-muted">Created By:</span>{' '}
            <span className="fw-bold">
              {item.created_by.person.first_name} {item.created_by.person.last_name}
            </span>
          </div>
          <div style={{ marginTop: '5px' }}>
            <span className="text-muted">Assigned To:</span>{' '}
            <span className="fw-bold">
              {item.assigned_to_employees?.length ? getEmployee(item) : '--'}
            </span>
          </div>
        </div>
      </td>

      {!condensed &&
        <td style={{ paddingLeft: "10px", width: "25%" }}>
          <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
            {item.where_and_what?.[0]?.service_items?.map((serviceItem, index) => (
              <Chip
                key={index}
                label={serviceItem.entity_type?.name || "Unknown"}
                size="small"
                sx={{ backgroundColor: "#EFFDEE", color: "#006064" }}
              />
            ))}
          </div>
        </td>
      }

      {condensed ? (
        <td className="text-end" style={{ width: "15%" }}>
            <span className={`fw-bold`}>
              {"Urgency: " + label}
            </span>
        </td>
      ) : (
        <td className="text-end" style={{ width: "15%" }}>
          <ProgressBar
            progress={item.status === "Closed" ? 100 : value}
            label={item.status === "Closed" ? "Completed" : "Urgency: " + label}
            colorClass={item.status === "Closed" ? "bg-success" : colorClass}
            subtext={
              item.status === "Closed"
                ? ""
                : item.is_overdue
                  ? `${item.time_overdue} Overdue`
                  : `${item.time_remaining} Remaining (Due: ${formatDate(item.due_date).slice(0, -5)})`
            }
          />
        </td>
      )}

    </tr>
  );
};

export {PrintableWorkItemRow};