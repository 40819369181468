import { AppDate } from "../utils/date-time/AppDate";

export class JointInspectionSummaryModel {
  joint_inspection_count: number;
  joint_inspection_score: number;
  inspected_spaces_count: number;
  last_inspection_date: AppDate | null;

  constructor() {
    this.joint_inspection_count = 0;
    this.joint_inspection_score = 0;
    this.inspected_spaces_count = 0;
    this.last_inspection_date = null;
  }
}
