import useAppStore from "../../../../appStore";
import React, { useEffect, useState } from "react";
import EquipmentTable from "./components/EquipmentTable";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { EquipmentService } from "../../../../shared/services/equipment/equipmentService";

const EquipmentList = (props) => {
  const {
    onEdit,
  } = props;

  const service = new EquipmentService();
  const [error, setError] = useState("");
  const [equipment, setEquipment] = useState<any>([]);
  const {masterRefresh} = useAppStore();

  useEffect(() => {
    setError("");
    service.getEquipment(1)
      .then(equipmentData => {
        setEquipment(equipmentData);
      })
      .catch(error => {
        setError(error.message);
      });
  }, [masterRefresh]);

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <EquipmentTable equipment={equipment} onEdit={onEdit} />
    </>
  );
};

export default EquipmentList;