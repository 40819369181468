import React from 'react';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import useTrainingStore from "../../../trainingStore";
import {
  findTreeNode
} from "../../../../../../shared/components/forms/widgets/searchable-tree-view/utils/findTreeNode";
import {TreeItem, TreeItemClasses, TreeView} from '@mui/x-tree-view';


const TrainingCatalogTree = (props) => {
  const {
    data = [],
    onItemSelected,
    onRenderLabel = null,
    classes = ''
  } = props;

  const treeItemClasses: TreeItemClasses = {
    root: '',
    group: '',
    content: '',
    expanded: '',
    selected: '',
    focused: '',
    disabled: '',
    iconContainer: '',
    label: 'fs-5'
  }

  const { selectedCategory, setSelectedCategory } = useTrainingStore();
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [expandedNodes, setExpandedNodes] = React.useState([]);
  const [categoryData, setCategoryData] = React.useState(data);


  const handleCategorySelected = (event, nodeId) => {
    // Check if the click was on the expand/collapse icon
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      // Do not proceed with selection
      return;
    }

    // Proceed with selection as usual
    let selectedItem = null;
    for (let item of categoryData) {
      selectedItem = findTreeNode(item, Number(nodeId));
      if (selectedItem !== null) {
        setSelectedItem(selectedItem);
        onItemSelected(selectedItem);
        return;
      }
    }
  };


  const renderLabel = (labelName) => {
    if (onRenderLabel !== null) {
      return onRenderLabel(labelName);
    }

    return (
      <div className='d-flex justify-content-between align-items-center'>
        <span>{labelName}</span>
      </div>
    );
  };


  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleDeselect = () => {
    setSelectedItem(null);
    setSelectedCategory(null);
    setExpandedNodes([]);
  }

  const renderTree = (node, type) => {
    if (node.matched !== undefined && !node.matched) {
      return null;
    }

    return (
      <TreeItem
        key={node.id}
        nodeId={`${node.id.toString()}`}
        label={renderLabel(node.name)}
        classes={treeItemClasses}
        sx={{
          '& .MuiTreeItem-content': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '& .MuiTreeItem-label': {
            fontSize: '1.2rem',
          },
          '& .MuiTreeItem-iconContainer svg': {
            fontSize: '1.5rem',
          },
        }}
      >
        {Array.isArray(node.children)
          ? node.children.map((node) => renderTree(node, type))
          : null}
      </TreeItem>
    );
  };


  return (
    <>
      <div className={classNames("", classes)}>
        {selectedItem && (
          <div style={{ marginBottom: '10px' }}>
            <Chip
              label={selectedItem.name}
              onDelete={handleDeselect}
            />
          </div>
        )}

        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
          defaultExpandIcon={<ChevronRightIcon sx={{ fontSize: '2rem' }} />}
          onNodeSelect={handleCategorySelected}
          onNodeToggle={handleToggle}
          expanded={expandedNodes}
          sx={{ width: '100%', height: '100%', fontSize: '2rem' }}
        >
          {categoryData.map((item) => renderTree(item, item.name))}
        </TreeView>

      </div>
    </>
  );
};

export default TrainingCatalogTree;
