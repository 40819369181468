import { Organization } from "./organization.model";

export class LanguageModel {

  id: number;
  name: string;
  native_name: string;
  code: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.native_name = '';
    this.code = '';
  }

}


