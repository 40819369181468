import { GroupEntry } from "./groupEntry.model";

export class CaseType {

  id: number;
  name: string;
  department: GroupEntry;

  constructor() {
    this.id = 0;
    this.name = "";
    this.department = new GroupEntry();
  }

}
