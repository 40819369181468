import React, { useEffect, useState } from "react";
import {
    FormControl,
    Stack,
    Chip,
    Tabs,
    Tab,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import useAppStore from "../../../../appStore";
import { CasesService } from "../../../../shared/services/cases/casesService";
import { Case } from "../../../../shared/models/case.model";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseTable from "./components/desktop/CaseTable";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";
import { useTranslation } from "react-i18next";


const CasesList = ({ onEdit, locationMode=false }) => {
    const { loggedInUser, appImages, masterRefresh, selectedLocation } = useAppStore();
    const service = new CasesService();
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState(0);
    const [cases, setCases] = useState<Case[]>([]);
    const [filteredCases, setFilteredCases] = useState<Case[]>([]);
    const [typeCounts, setTypeCounts] = useState<any>(null);
    const [selectedTypeID, setSelectedTypeID] = useState<number | null>(null);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTypeCounts = async () => {
            try {
                const counts = await service.getCaseTypeCounts(loggedInUser.organization.id, locationMode ? selectedLocation.id : undefined);
                setTypeCounts(counts);

                if (counts?.type_counts?.length === 1) {
                    handleCaseTypeSelect(counts.type_counts[0].case_type_id);
                }
            } catch (error) {
                setError(error instanceof Error ? error.message : String(error));
            }
        };

        fetchTypeCounts().then();
    }, [loggedInUser.organization.id, selectedLocation.id]);

    const fetchCases = async () => {
        setIsLoading(true);
        const limit = 10;
        const offset = page * limit;

        try {
            const active = await service.getCases(
              loggedInUser.organization?.id,
              null,
              locationMode ? selectedLocation.id : undefined,
              activeTab === 0 ? 'Waiting' : 'Completed',
              selectedTypeID,
              limit,
              offset
            );

            if (page === 0) {
                setCases(active);
            } else {
                setCases(prevCases => [...prevCases, ...active]);
            }

            setFilteredCases(prev => (page === 0 ? active : [...prev, ...active]));
        } catch (error) {
            setError(error instanceof Error ? error.message : String(error));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (locationMode && selectedLocation.id || !locationMode) {
            fetchCases().then();
        }

    }, [masterRefresh, activeTab, page, selectedTypeID, selectedLocation.id]);

    const handleCaseTypeSelect = (id: number | null) => {
        setSelectedTypeID(prev => (prev === id ? null : id));
        setPage(0);
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setPage(0);
    };

    const handleShowMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (error) return <CustomError error={error} />;

    return (
      <>
          {typeCounts && (
            <FormControl fullWidth style={{ marginBottom: '10px' }}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Chip
                      label="All Types"
                      clickable
                      color={selectedTypeID === null ? 'primary' : 'default'}
                      onClick={() => handleCaseTypeSelect(null)}
                      style={{ marginBottom: 5, marginLeft: 10 }}
                    />
                    {typeCounts?.type_counts?.map((item) => (
                      <Chip
                        key={item.case_type_id}
                        label={`${item.case_type} (${item.count})`}
                        clickable
                        color={selectedTypeID === item.case_type_id ? 'primary' : 'default'}
                        onClick={() => handleCaseTypeSelect(item.case_type_id)}
                        style={{ marginBottom: 5 }}
                      />
                    ))}
                </Stack>
            </FormControl>
          )}

          <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                  <Tab label={t('open')} />
                  <Tab label={t('closed')} />
              </Tabs>
          </div>

          {(!isLoading || page !== 0) && filteredCases.length > 0 && (
            <CaseTable
              cases={filteredCases}
              onEdit={onEdit}
              isLoading={isLoading}
            />
          )}

          {!isLoading && filteredCases.length === 0 && (
            <div style={{ textAlign: "center", margin: "20px", padding: "20px" }}>
                <img
                  src={appImages.iconAlert}
                  style={{ width: '150px', height: '150px', marginBottom: '10px' }}
                />
                <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                    No cases found
                </p>
                <p>To view cases you must be part of a department or location with active cases</p>
            </div>
          )}

          {((filteredCases.length % 10 === 0 && filteredCases.length > 0) || isLoading) && (
            <LoadMoreButton onClick={handleShowMore} isLoading={isLoading} />
          )}
      </>
    );
};

export default CasesList;