import React from "react";
import EquipmentRow from "./EquipmentRow";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getCardClass } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";


const EquipmentTable = (props) => {

  const{
    equipment,
    canEdit,
    onEdit,
  } = props

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <>
      <div className={getCardClass(isMobile)}>
        <div className="card-body w-100">
          <div className="table-responsive w-100">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 w-100">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                    <th className="p-0 pb-3 min-w-200px text-start">ITEM</th>
              </tr>
              </thead>

              <tbody>
              {equipment.map((item) => (
                <EquipmentRow key={item.id}
                             item={item}
                             onEdit={onEdit}
                             onDelete={null}
                             canEdit={canEdit}
                             isMobileView={isMobile}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

}

export default EquipmentTable