import React, { useEffect, useRef, useState } from "react";
import {
  Dialog
} from "@mui/material";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import { useEditInspectedEntity } from "../edit-inspected-entity/useEditInspectedEntity";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import AssistedInspectionNavigator from "./components/AssistedInspectionNavigator";

const AssistedInspectionModal = (props) => {

  const {
    open,
    onActionClicked,
  } = props

  const [step, setStep] = useState(0);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }, [step]);

  const {
    isLoading, error,
  } = useEditInspectedEntity();

  const handleCancel = () => {
    setStep(0);
    onActionClicked();
  }

  if (isLoading) {
    return <CustomLoadingDots />;
  }
  if (error) {
    return <CustomError error={error} />;
  }

  return (

    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        TransitionComponent={ModalTransition}
        PaperProps={{
          ref: modalContentRef,
          style: {
            maxHeight: '90vh',
            overflowY: 'auto',
          }
        }}
      >

        <AssistedInspectionNavigator onClose={handleCancel} step={step} setStep={setStep} />

      </Dialog>
    </>

  )

}

export default AssistedInspectionModal;
