import React, { useState, useEffect } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import MediaUploader from "../../../../../shared/components/media/media-uploader/MediaUploader";
import useAppStore from "../../../../../appStore";
import PdfViewer from "../../../../../shared/components/media/media-viewers/PdfViewer";
import { getMediaType } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";

const LanguageMediaDisplay = ({ media, label, iconNoPdf, onMediaClick, uploadUrl, onUploadComplete, readOnly=false }) => {
  const { loggedInUser } = useAppStore();
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handleOpen = (url) => {
    if (getMediaType(url) === "pdf") {
      setPdfUrl(url);
      setPdfOpen(true);
    } else {
      onMediaClick(url);
    }
  };

  const handleClose = () => {
    console.log("Closing PDF viewer...");
    setPdfOpen(true);  // Temporarily set to true to force update
    setTimeout(() => setPdfOpen(false), 0);  // Set to false after a short delay
  };


  useEffect(() => {
    console.log("pdfOpen state changed:", pdfOpen); // Log state to verify changes
  }, [pdfOpen]); // Monitor pdfOpen changes to ensure it updates

  return (
    <Box
      width={200}
      height={250}
      border={1}
      borderColor="grey.300"
      margin={1}
      sx={{
        position: "relative",
        borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
        cursor: media.url ? "pointer" : "default",
      }}
      onClick={() => media.url && handleOpen(media.url)}
    >
      {media.url ? (
        <CardMedia
          component="img"
          image={getMediaType(media.url) === "image" ? media.url : "/white-labeling/verde/media/menu-icons/pdf-icon.png"}
          alt={label}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <CardMedia
          component="img"
          image={iconNoPdf}
          alt="No data"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          bottom: 0,
          backgroundColor: "#00796b",
          color: "white",
          width: "100%",
          textAlign: "center",
          padding: "5px 0",
          fontSize: '1.3rem'
        }}
      >
        {label}
      </Typography>

      {!readOnly &&
        <Box sx={{ position: "absolute", top: 8, right: 8 }}>
          <MediaUploader
            uploadUrl={uploadUrl}
            onUploadComplete={onUploadComplete}
            organization_id={3}
            person_id={loggedInUser.id}
            module="courses"
          />
        </Box>
      }


      {/* Ensure PdfViewer has the correct onClose prop */}
      {pdfOpen && <PdfViewer open={pdfOpen} onClose={handleClose} url={pdfUrl} />}
    </Box>
  );
};

export default LanguageMediaDisplay;




