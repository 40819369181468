import React from 'react'
import { Box, Button } from "@mui/material";
import useServiceStore from "../../../../serviceStore";
import ServiceItemDropdown from "./ServiceItemDropdown";
import ServiceItemEdit from "./ServiceItemEdit";
import useAppStore from "../../../../../../appStore";
import { Add } from "@mui/icons-material";

const SelectedServicePreview = (props) => {

  const {
    onAdd,
    onEdit
  } = props;

  const {selectedService, selectedServiceItem} = useServiceStore();
  const {appImages} = useAppStore();
  const [isEditing, setIsEditing] = React.useState(false);

  const handleAddServiceItem = () => {

  }

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  }

  return (
    <>
      <div className="d-flex flex-column flex-row-fluid"
           key={selectedService ? selectedService.id : "no-service"}>
        {selectedService && (
          <>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex flex-row"
                   style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                <div className="d-flex flex-row align-items-center">
                  <h3 style={{ marginTop: "5px", marginRight: "8px" }}>{selectedService?.name} : </h3>
                  <ServiceItemDropdown />
                </div>
              </div>
              <Button variant="contained" onClick={handleAddServiceItem} style={{height: '40px'}}>
                <Add sx={{marginRight: '5px'}}/> Service Item
              </Button>
            </div>

          </>
        )}
        {selectedService?.id ? (
          <>
            <ServiceItemEdit isEditing={isEditing} setIsEditing={setIsEditing} />
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" textAlign="center" p={2} mt={15}>
            <div>
              <img src={appImages.iconService}
                   style={{ width: "175px", height: "175px", marginBottom: "10px" }} />
              <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                No Service Selected.
              </p>
              <p>Select a service to view/edit.</p>
            </div>
          </Box>
        )}
      </div>
    </>
  )
}

export default SelectedServicePreview