import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  getEmployee,
  getFloor, getEntityTypeName, getBuildingName, formatDate, calculateProgress
} from "../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ActionButton from "../../../../../../../../shared/components/buttons/ActionButton";
import { useWorkItem } from "../../../../../../context/workItemContext";
import WorkItemCardSubDetails from "./WorkItemCardSubDetails";
import { toAbsoluteUrl } from "../../../../../../../../../_metronic/helpers";
import ProgressBar
  from "../../../../../../../analytics/components/site-health/components/tabs/corp-overview/components/card/components/ProgressBar";
import { useTranslation } from "react-i18next";


const WorkItemDesktopCard = (props) => {
  const {
    workItem,
  } = props;

  const { t } = useTranslation();
  const { setWorkItemToEdit, setOpen } = useWorkItem();
  const isClosed = (workItem.status === "Closed");
  const serviceItems = workItem.where_and_what?.[0]?.service_items || [];

  const { value, colorClass, label } = calculateProgress(workItem.urgency);


  return (
    <>
      <Card variant="outlined" sx={{ marginTop: '15px', marginBottom: '15px', boxShadow: '0 1px 4px rgba(0,0,0,0.1)', position: 'relative', paddingBottom: 0 }}>

        <Box sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#f6f6f6",
          color: "black",
          padding: "5px 8px",
          borderRadius: "0 0 6px 0",
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <img src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/inspectorProfile.png')} alt={'Employee'}
               style={{ width: "40px", height: "40px", marginRight: "6px", border: '1px solid #c4c4c4', borderRadius: '50%' }} />
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555", marginRight: '8px' }}>{(workItem.assigned_to_employees?.length) ? getEmployee(workItem) : `${t('unassigned')}`}</Typography>
        </Box>

        <Box sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "8px 8px",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
          <div className="d-flex flex-row align-items-center">
            <ActionButton
              iconType={"view"}
              onClick={(e) => {
                e.preventDefault();
                setWorkItemToEdit(workItem);
                setOpen(true);
              }}
            />
          </div>
        </Box>

        <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px dashed #c6c6c6', width: '100%', paddingTop: '50px', paddingBottom: '0px' }}>
            {/* Left Column */}
            <Box sx={{ width: "50%", padding: '16px'}}>
              <div className="d-flex flex-column" style={{ width: "350px" }}>
                <ProgressBar
                  progress={isClosed ? 100 : value}
                  label={isClosed ? `${t('completed')}` : `${t('urgency')}: ` + label}
                  colorClass={isClosed ? "bg-success" : colorClass}
                  subtext={isClosed ? "" : workItem.is_overdue ? `${workItem.time_overdue} ${t('overdue')}` : `${workItem.time_remaining} ${t('remaining')} (Due: ${formatDate(workItem.due_date).slice(0, -5)})`} />
              </div>
            </Box>

            {/* Right Column */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'right', padding: '16px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right', alignItems: 'flex-end' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#555', fontSize: '1.2rem', marginRight: '3px' }}>
                    {getBuildingName(workItem)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1.2rem', marginTop: '2px' }}>
                    / {getFloor(workItem)} / {getEntityTypeName(workItem)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2px' }}>
                  <Typography variant="body2" color="primary" sx={{ fontSize: '1.1rem' }}>
                    {serviceItems[0]?.entity_type?.name || 'No Service Selected'}
                    {serviceItems && serviceItems.length > 1 && (
                      <Typography component="span" sx={{ marginLeft: '5px' }}>
                        +{serviceItems.length - 1} more
                      </Typography>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <WorkItemCardSubDetails workItem={workItem} />
        </CardContent>
      </Card>
    </>
  );
};

export default WorkItemDesktopCard;

