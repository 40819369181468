import { BaseSerializer } from "./baseSerializer";
import { LanguageModel } from "../language.model";

export class LanguageSerializer extends BaseSerializer {


    public deserialize( data ): any {

        const lang = new LanguageModel();

        this._copyAttributes(lang, data);

        return lang;

    }

}