import React, { useState, useEffect } from 'react';
import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import PhotoUploaderViewer from "../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { Media } from "../../../../shared/models/media.model";
import MediaDisplay from "../../../../shared/components/media/media-viewers/photos/MediaDisplay";

const EditSupply = ({ open, onClose, supply, setSupply, onSubmit, readOnly=false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [media, setMedia] = useState<Media[]>(supply.media || []);

  useEffect(() => {
    if (supply.id === 0) {
      setIsEditing(true);
    }
  }, [supply.id]);

  useEffect(() => {
    setSupply(prev => {
      const updatedWorkItem = {
        ...prev,
        media: media
      };
      return updatedWorkItem;
    });

  }, [media]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupply((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSubmit(supply);
    handleClose();
  };


  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div style={{ flexGrow: 1 }}></div>
          {!isEditing &&
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setIsEditing(true)}
              sx={{ ml: "auto", color: "#444444" }}
            >
              {"Edit Supply"}
            </Button>
          }
        </Toolbar>
      </AppBar>
      <DialogContent>
        {isEditing ?
          (
            <>
              <TextField
                margin="dense"
                name="name"
                label="Name"
                type="text"
                fullWidth
                value={supply.name}
                onChange={handleChange} />
              <TextField
                margin="dense"
                name="description"
                label="Information"
                type="text"
                fullWidth
                multiline
                rows={5}
                value={supply.description}
                onChange={handleChange}
                sx={{marginBottom: 2}}
              />
              <PhotoUploaderViewer
                item={supply}
                setMedia={setMedia}
                captions={false}
                module={'supplies'}
              />
            </>
          ) : (
            <>
              <div className="mb-3">
                <span className="text-gray-400 fw-semibold d-block fs-7">Name:</span>
                <span className="fs-7">{supply.name || "N/A"}</span>
              </div>
              <div className="mb-3">
                <span className="text-gray-400 fw-semibold d-block fs-7">Description:</span>
                <span className="fs-7">{supply.description || "N/A"}</span>
              </div>
              <div className="mb-3">
                <MediaDisplay itemMedia={supply.media} captions={false} module={"supplies"} />
              </div>
            </>
          )
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>;
    </Dialog>
  )
  ;
};

export default EditSupply;
