import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme, Box } from "@mui/material";
import SubHeader from "../../../../../../../shared/components/page/page-header/SubHeader";
import { formatDate, getTimeDifferenceString } from "../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import useAppStore from "../../../../../../../appStore";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/helpers";
import { useTranslation } from "react-i18next";

const LastInspectionCard = ({ data, height = 120 }) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const {selectedLocation} = useAppStore();

  return (
    <Card variant="outlined" sx={{ height: isMobile ? '120px' : height }}>
      <CardContent sx={{ padding: !isMobile ? '16px' : '', display: !isMobile ? 'flex' : '', alignItems: !isMobile ? 'center' : '', height: '100%' }}>
      {!isMobile && (
        <Box sx={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
          <img src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/recent-clock.png')} style={{ width: "85px", height: "85px" }} />
        </Box>
      )}
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center' }}>
          <SubHeader title={t('last-inspection')} showAction={false} />
          <Typography variant="h6">{getTimeDifferenceString(data)}</Typography>
          {!isMobile &&
            <>
              <Typography variant="body2" color="textSecondary" component="p" sx={{ marginBottom: 0 }}>
                {formatDate(data)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {selectedLocation.name}
              </Typography>
            </>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

export default LastInspectionCard;

