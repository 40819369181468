import React from "react";
import { Box, Button, Divider, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import TrainingHierarchy from "./components/TrainingHierarchy";
import CourseListTable from "../course-list/CourseListTable";
import useAppStore from "../../../../../appStore";
import useTrainingStore from "../../trainingStore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const TrainingCatalogTreeViewer = (props) => {
  const {
    selectedCategory,
    handleAddCategory,
    handleNewCourse,
    handleEditCourse,
    readOnly = false,
  } = props;

  const { appImages } = useAppStore();
  const {t} = useTranslation();
  const {setSelectedCategory} = useTrainingStore();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const [view, setView] = React.useState("category"); // 'category' or 'course'

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setView("course");
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
    setView("category");
  };

  return (
    <>
      <div className="card">
        <div className="card-body" style={{ alignItems: "flex-start", padding: isMobile ? 0 : ''}}>
          {isMobile ? (
            <>
              {view === "category" ? (
                <div className="d-flex flex-column flex-row-auto p-2" style={{ minHeight: "600px", overflow: "auto" }}>
                  <TrainingHierarchy onCategorySelected={handleCategorySelect} />
                </div>
              ) : (
                <div className="d-flex flex-column">
                  <Box className="d-flex flex-row justify-content-between align-items-center mb-2 p-3"
                       sx={{ padding: 2, backgroundColor: "primary.main", color: "primary.contrastText" }}>
                    <div className='d-flex flex-row align-items-center' onClick={handleBackToCategories}>
                      <ArrowBackIcon />
                      <Typography variant="h6" component="div" sx={{ marginLeft: '5px', fontWeight: 'bold' }}>
                        {t('back-to-categories')}
                      </Typography>
                    </div>
                  </Box>
                  {!readOnly &&
                    <div className="d-flex flex-row-fluid justify-content-between align-items-center p-3 pt-5 pb-0">
                      <h3>{selectedCategory?.name}</h3>
                        <Button variant="contained" color="primary" onClick={handleNewCourse}
                                style={{ height: "35px" }}>
                          + {t('course')}
                        </Button>
                    </div>
                  }
                  <Box sx={{ borderBottom: 0 }} className="mt-0 mb-10 p-2">
                    <CourseListTable
                      onEditCourseClicked={handleEditCourse}
                      selectedCategory={selectedCategory}
                      readOnly={readOnly}
                    />
                  </Box>
                </div>
              )}
            </>
          ) : (
            // Desktop view: Two-panel layout
            <div className="d-flex flex-row" style={{ alignItems: "flex-start" }}>
              <div className="d-flex flex-column flex-row-auto w-400px" style={{ minHeight: "600px", overflow: "auto" }}>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h3>{t('categories')}</h3>
                  </div>
                <TrainingHierarchy onCategorySelected={handleCategorySelect} />
              </div>
              <Divider orientation="vertical" flexItem style={{ backgroundColor: "gray", marginLeft: "15px" }} />
              <div className="d-flex flex-column flex-row-fluid ms-5">
                {selectedCategory?.name ? (
                  <>
                    <div className="d-flex flex-row-fluid justify-content-between">
                      <h3>{selectedCategory.name}</h3>
                      {!readOnly && (
                        <Button variant="contained" color="primary" onClick={handleNewCourse} style={{ height: "35px" }}>
                          + {t('course')}
                        </Button>
                      )}
                    </div>
                    <Box sx={{ borderBottom: 0 }} className="mt-5 mb-10">
                      <CourseListTable
                        onEditCourseClicked={handleEditCourse}
                        selectedCategory={selectedCategory}
                        readOnly={readOnly}
                      />
                    </Box>
                  </>
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%" textAlign="center" p={2} mt={15}>
                    <div>
                      <img src={appImages.iconTraining} style={{ width: "175px", height: "175px", marginBottom: "10px" }} />
                      <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>{t('no-category-selected')}</p>
                      <p>{t('select-category')}</p>
                    </div>
                  </Box>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainingCatalogTreeViewer;
