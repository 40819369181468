import { BaseSerializer } from "./baseSerializer";
import { SurveySummary } from "../surveySummary.model";
import { JointInspectionSummaryModel } from "../jointInspectionSummary.model";

export class JointSummarySerializer extends BaseSerializer {
    public deserialize(data): any {
        const summaryData = data.joint_inspection_summary && data.joint_inspection_summary.length > 0 ? data.joint_inspection_summary[0] : new JointInspectionSummaryModel();
        const summary = new JointInspectionSummaryModel();

        if (summaryData) {
            summary.joint_inspection_score = summaryData.joint_inspection_score !== null ? parseFloat(summaryData.joint_inspection_score) : 0;
            summary.joint_inspection_count = summaryData.joint_inspection_count;
            summary.inspected_spaces_count = summaryData.inspected_spaces_count;
            summary.last_inspection_date = summaryData.last_inspection_date;
        }

        return summary;
    }
}