import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAppStore from "../../../appStore";
import useTrainingStore from "../training-catalog/trainingStore";
import PageHeader from "../../../shared/components/page/page-header/PageHeader";
import TrainingCatalogTreeViewer from "../training-catalog/components/catalog-tree-viewer/TrainingCatalogTreeViewer";
import EditCourse from "../training-catalog/components/edit-course/EditCourse";

const TrainingReadOnlyPage = () => {

  const { t } = useTranslation();
  const {masterRefresh, toggleMasterRefresh} = useAppStore();
  const { selectedCategory, setSelectedCategory, fetchCategories, trainingCategories, courseToEdit, setCourseToEdit, isEditingCourse, setIsEditingCourse } = useTrainingStore();
  const { loggedInUser } = useAppStore();


  useEffect(() => {
    const selectedCategoryId = selectedCategory?.id;

    fetchCategories(loggedInUser.organization?.id).then(() => {
      if (selectedCategoryId) {
        const refreshedCategory = trainingCategories.find((category) => category.id === selectedCategoryId);
        setSelectedCategory(refreshedCategory || null);
      }
    });
  }, [masterRefresh]);


  function handleViewCourse(course) {
    setCourseToEdit(course);
    setIsEditingCourse(true);
  }

  function handleNewCourse() {
    console.log("read only")
  }


  function handleCloseCourse() {
    setIsEditingCourse(false);
    toggleMasterRefresh();
  }

  function handleAddCategory() {
    console.log("read only")
  }

  async function handleSaveCourse() {
    console.log("read only")
  }



  return (
    <>
          <PageHeader title={t('employee-training')}
                      showAction={false}
          />
          <TrainingCatalogTreeViewer
            selectedCategory={selectedCategory}
            handleAddCategory={handleAddCategory}
            handleEditCourse={handleViewCourse}
            handleNewCourse={handleNewCourse}
            readOnly={true}
          />
          {isEditingCourse && (
            <EditCourse
              open={isEditingCourse}
              course={courseToEdit}
              setCourse={setCourseToEdit}
              onClose={handleCloseCourse}
              onSubmit={handleSaveCourse}
              readOnly={true} />
          )}
    </>
  );

};


export default TrainingReadOnlyPage;