import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, TextField, Typography } from "@mui/material";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import ParentSelection from "../../../../service-catalog/components/edit-service/ParentSelection";
import { ValidationDialog } from "../../../../../shared/components/page/popup-dialog/ValidationDialog";
import useTrainingStore from "../../trainingStore";
import { TrainingCategory } from "../../../../../shared/models/training-category.model";

const CategoryDetails = ({ classes = '', onSave}) => {
  const { selectedCategory, setSelectedCategory, trainingCategories} = useTrainingStore();
  const [name, setName] = useState(selectedCategory?.name || '');
  const [description, setDescription] = useState(selectedCategory?.description || '');
  const [parent, setParent] = useState<TrainingCategory | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);


  useEffect(() => {
    if (selectedCategory) {
      setName(selectedCategory.name || '');
      setDescription(selectedCategory.description || '');
      setParent(selectedCategory.parent || undefined);
    }
  }, [selectedCategory]);


  const handleSave = () => {
    if (!name.trim() || !description.trim()) {
      setShowErrorDialog(true);
      return;
    }

    setSelectedCategory(prev => {
      if (!prev) {
        return new TrainingCategory();
      }

      const updated: TrainingCategory = {
        ...prev,
        name: name,
        description: description,
        parent: parent,
      };
      return updated;

    });

  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveParent = (selected) => {
    if (selectedCategory && selected.id === selectedCategory.id) {
      alert("A category cannot select itself as its parent.");
      setParent(undefined);
    } else {
      setParent(selected);
      console.log(selected);
      setIsDialogOpen(false);
    }
  };


  return (
    <div className={classes}>
      <div className="d-flex flex-row align-items-center justify-content-between" style={{ paddingLeft: 0 }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">Details</span>
        </h3>
      </div>
      <div>
        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} sx={{marginBottom: '10px'}} fullWidth />
        <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} sx={{marginBottom: '10px'}} fullWidth multiline />


        {/* Parent Category Field */}
        <div>
          <Card variant="outlined" style={{ marginBottom: '10px' }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                <EscalatorWarningIcon /> Parent Category
              </Typography>
              <Typography variant="body1">{parent?.name || ''}</Typography>
              <Button onClick={handleOpenDialog}>Select Parent Category</Button>
            </CardContent>
          </Card>

          <ParentSelection
            open={isDialogOpen}
            onClose={handleCloseDialog}
            onSelect={handleSaveParent}
            itemToEdit={null}
            data={trainingCategories}
          />


        </div>
      </div>
      <ValidationDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} title="Missing Information"
                        description="All cateogies require a name and description." />

      <div className="button-container"
           style={{
             position: 'relative',
             bottom: 0,
             left: 0,
             right: 0,
             zIndex: 1000,
             width: '100%'
           }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ width: "100%", height: '50px', borderRadius: 0, borderTop: '1px solid #e7e7e7' }}
        >
          {'SAVE'}
        </Button>
      </div>
    </div>
  );
};

export default CategoryDetails;