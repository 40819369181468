// PrintableWorkItemSummaryPage.tsx

import React from 'react';
import { WorkItem } from '../../../../../shared/models/workItem.model';
import { PrintableWorkItemRow } from "./PrintableWorkItemRow";

interface PrintableWorkItemSummaryPageProps {
  items: WorkItem[];
}

const PrintableWorkItemSummaryPage: React.FC<PrintableWorkItemSummaryPageProps> = ({ items }) => {
  const today = new Date().toLocaleDateString();

  return (
    <div className="summary-page" style={{ pageBreakAfter: 'always', padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Work Item Huddle</h1>
      <p style={{ textAlign: 'center' }}>{today}</p>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <tbody>
        {items.map((item) => (
            <PrintableWorkItemRow
              key={item.id}
              item={item}
              onSelect={() => console.log("selected")}
              isSelected={false}
              condensed={true}
            />
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintableWorkItemSummaryPage;
