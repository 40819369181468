// analyticDataFetchHelper.ts
import { AnalyticsService } from "../../services/analytics/analyticsService";



// work item fetching
// site health fetching
// work item analytics fetching
// location inspection analytics


export const fetchIssuesChart = async (organizationId: number, locationId: string, chartType: string, metric: string, startDate: string | null, endDate: string | null) => {
  const service = new AnalyticsService();
  const response = await service.getCharts(organizationId, locationId, chartType, metric, startDate, endDate);

  if (response && response[metric].length > 0) {
    const transformedData = response[metric].map(item => ([
      item.name,
      parseFloat(item.count)
    ]));
    const maxCount = Math.max(...transformedData.map(row => row[1]));
    return { transformedData, maxCount };
  } else {
    throw new Error('Issues has no data: This chart is populated with inspection submissions');
  }
};

export const fetchMonthlyScores = async (
  organizationId: number,
  location_id: number | undefined,
  startDate: string | null,
  endDate: string | null
) => {
  const service = new AnalyticsService();
  const response = await service.getCharts(
    organizationId,
    location_id,
    'inspections',
    'scores_by_month',
    startDate,
    endDate
  );

  // Check for the correct field
  if (response && response.scores_by_month && response.scores_by_month.length > 0) {
    return response.scores_by_month;
  } else {
    throw new Error("Monthly scores have no data: This chart is populated with inspection submissions");
  }
};


export const fetchScoresOverallChart = async (organizationId: number, startDate: string | null, endDate: string | null) => {
  const service = new AnalyticsService();
  const response = await service.getCharts(organizationId, "0", 'inspections', 'scores_by_location', startDate, endDate);

  if (response && response.scores_by_location.length > 0) {
    const transformedData = response.scores_by_location.map(item => ([
      item.location_name,
      parseFloat(item.avg_score),
      parseFloat(item.avg_joint_score)
    ]));
    return [['Location', 'Average', 'Joint Average'], ...transformedData];
  } else {
    throw new Error('Overall space scores has no data: This chart is populated with inspection submissions');
  }
};

export const fetchScoresByType = async (organizationId: number, locationId: number, startDate: string | null, endDate: string | null) => {
  const service = new AnalyticsService();
  const response = await service.getCharts(organizationId, locationId, 'inspections', 'scores_by_entity_type', startDate, endDate);

  if (response && response.scores_by_entity_type.length > 0) {
    let transformedData;
    if (locationId === 0) {
      transformedData = response.scores_by_entity_type.map(item => ([
        item.name,
        parseFloat(item.avg_score),
        parseFloat(item.avg_joint_score)
      ]));
    } else {
      transformedData = response.scores_by_entity_type.map(item => ([
        item.name,
        parseFloat(item.avg_score)
      ]));
    }
    return transformedData;
  } else {
    throw new Error('Scores by type has no data: This chart is populated with inspection submissions');
  }
};

export const fetchScoresBySpace = async (organizationId: number, locationId: number, startDate: string | null, endDate: string | null) => {
  const service = new AnalyticsService();
  const response = await service.getCharts(organizationId, locationId.toString(), 'inspections', 'scores_by_top_level_space', startDate, endDate);

  if (response && response.scores_by_top_level_space.length > 0) {
    const transformedData = response.scores_by_top_level_space.map(item => ([
      item.name,
      parseFloat(item.avg_score)
    ]));
    return transformedData;
  } else {
    throw new Error('Scores by space has no data: This chart is populated with inspection submissions');
  }
}

