import { create } from 'zustand'
import { LocationModel } from '../app/shared/models/location.model'
import { LoggedInUser } from './shared/models/loggedInUser.model';
import { WhiteLabelProps } from './shared/models/whiteLabelProps.model';
import { AppImages } from "./shared/models/appImages.model";

interface AppStore {
  loggedInUser: LoggedInUser;
  selectedLocation: LocationModel;
  setLoggedInUser: (user: LoggedInUser | undefined) => void;
  setSelectedLocation: (location: LocationModel | undefined) => void;
  whiteLabelProps: WhiteLabelProps;
  appImages: AppImages;
  masterRefresh: boolean;
  toggleMasterRefresh: () => void;
}

const useAppStore = create<AppStore>((set, get) => ({
  loggedInUser: new LoggedInUser(),
  selectedLocation: new LocationModel(),
  setLoggedInUser: (loggedInUser) => set({ loggedInUser }),
  setSelectedLocation: (selectedLocation) => set({ selectedLocation }),
  whiteLabelProps: new WhiteLabelProps(),
  appImages: new AppImages(),
  masterRefresh: false,
  toggleMasterRefresh: () => {
    const current = get().masterRefresh;
    const newRefresh = !current;
    console.log(`toggleMasterRefresh called. Changing masterRefresh from ${current} to ${newRefresh}`);
    set({ masterRefresh: newRefresh });
  },
}));


export default useAppStore;
