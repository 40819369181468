import React, { useEffect, useState } from "react";
import CustomError from "../../../../../shared/components/page/popup-dialog/CustomError";
import { EntityTypeService } from "../../../../../shared/services/entity-types/entityTypeService";
import { EntityType } from "../../../../../shared/models/entityType.model";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { Button, Divider } from "@mui/material";
import SpaceTypesTable from "./components/left-panel/SpaceTypesTable";
import useAppStore from "../../../../../appStore";
import EditSpaceType from "./components/right-panel/EditSpaceType";
import { ConfirmationDialog } from "../../../../../shared/components/page/popup-dialog/ConfirmationDialog";

const SpaceTypesDualViewer = ({global = true}) => {

  const { loggedInUser, masterRefresh, toggleMasterRefresh } = useAppStore()
  const { selectedLocation } = useAppStore()
  const service = new EntityTypeService();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [spaceTypes, setSpaceTypes] = useState<EntityType[]>([]);
  const [selectedSpaceType, setSelectedSpaceType] = useState<EntityType | null>(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (!global && selectedLocation.id === 0) {
      return;
    }

    setError("");
    setIsLoading(true);
    setSpaceTypes([]);
    service.getEntityTypes(!global ? selectedLocation.id : null)
      .then(data => {
        setSpaceTypes(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [masterRefresh, selectedLocation]);


  const handleAddSpaceType = () => {
    setSelectedSpaceType(new EntityType());
  };

  const handleSelectSpaceType = (spaceType) => {
    setSelectedSpaceType(spaceType);
  };

  const handleSave = async (spaceType?) => {
    if (global) {
      try {
        if (selectedSpaceType) {
          selectedSpaceType.organization = loggedInUser.organization;
          selectedSpaceType.kind_of = 'Place';
        }
        if (selectedSpaceType?.id && selectedSpaceType?.id !== 0) {
          await new EntityTypeService().updateEntityType(selectedSpaceType);
        } else if (selectedSpaceType) {
          await new EntityTypeService().createEntityType(selectedSpaceType);
        }
      } catch (e) {
        console.error('Error while saving:', e);
      } finally {
        toggleMasterRefresh();
      }
    } else {
      try {
        await new EntityTypeService().linkEntityType(spaceType, selectedLocation.id);
      } catch (e) {
        console.error('Error while saving:', e);
      } finally {
        toggleMasterRefresh();
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedSpaceType(prevState => {
      if (prevState === null) {
        return null;
      }
      return {
        ...prevState,
        name: value
      };
    });
  };

  const handleConfirmDelete = async () => {
      try {
        if (global) {
          await service.deleteEntityType(selectedSpaceType?.id);
        } else {
          await service.unlinkEntityType(selectedSpaceType, selectedLocation.id);
        }
        setConfirmDeleteOpen(false);
        toggleMasterRefresh();
        setSelectedSpaceType(null);
      } catch (error) {
        console.error(error);
      }
  };

  const handleDelete = () => {
    setConfirmDeleteOpen(true);
  };

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <div className="card">
      <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>

        {/* Left */}
        <div className="d-flex flex-column flex-row-auto w-250px" style={{ minHeight: '600px', overflow: 'auto' }}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3>Types</h3>
            <Button variant="contained" color="primary" onClick={handleAddSpaceType}>
              {global ? '+ Type' : '+ Link'}
            </Button>
          </div>
          {isLoading ? (
            <CustomLoadingDots />
          ) : (
            <SpaceTypesTable onSelect={handleSelectSpaceType} spaceTypes={spaceTypes} />
            )}
        </div>

        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'black', marginLeft: '15px' }} />

        {/* Right */}
        <div className="d-flex flex-column flex-row-fluid ms-5">
          {selectedSpaceType !== null ? (
            <>
              <EditSpaceType global={global} locationTypes={spaceTypes} selectedSpaceType={selectedSpaceType} setSelectedSpaceType={setSelectedSpaceType} onChange={handleNameChange} onSave={handleSave} onDelete={handleDelete} />
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "50px" }}>
              <div>
                <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                  No Space Type Selected.
                </p>
                <p>Select a type from the left panel or add a new one.</p>
                <Button color="primary" onClick={handleAddSpaceType} style={{ height: '35px' }}>
                  + Space Type
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {confirmDeleteOpen && (
        <ConfirmationDialog
          isOpen={confirmDeleteOpen}
          setIsOpen={setConfirmDeleteOpen}
          message={global ? 'Delete Space Type?' : 'Unlink Space Type?'}
          description={global ? "This action cannot be undone. Are you sure you want to delete this space type?" : "Are you sure you want to unlink this space type?"}
          onConfirmClicked={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default SpaceTypesDualViewer;

