import React from "react";
import {
  AppBar, Box, Dialog, Grid, IconButton,
  Tabs, Toolbar, Typography, Button, Tab, useTheme
} from "@mui/material";
import { Card } from "react-bootstrap";
import { CloseIcon } from "yet-another-react-lightbox";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import ServiceDetails
  from "../catalog-tree-viewer/components/right-panel/service-sections/components/details/ServiceDetails";

const EditService = ({ open, onClose, onSave }) => {

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Service
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="card">
        <div className="card-body">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className="mb-3">
                <Card.Body>
                  <ServiceDetails onSave={onSave} />
                </Card.Body>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>

    </Dialog>
  );
}

export default EditService;

