import React, { useState } from 'react';
import PageHeader from "../../../../shared/components/page/page-header/PageHeader";
import CorpOverviewAnalytics from "./components/tabs/corp-overview/CorpOverviewAnalytics";
import WorkItemsAnalytics from "./components/tabs/work-items/WorkItemsAnalytics";
import InspectionOverviewAnalytics from "./components/tabs/inspections/InspectionOverviewAnalytics";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

const SiteHealthPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const {t} = useTranslation();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <PageHeader
        title={t('overview')}
        showAction={false}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="Site Health Tabs">
          <Tab label={t('locations')} />
          <Tab label={t('work-items')} />
          <Tab label={t('inspections')} />
        </Tabs>
      </Box>
      {selectedTab === 0 && <CorpOverviewAnalytics />}
      {selectedTab === 1 && <WorkItemsAnalytics />}
      {selectedTab === 2 && <InspectionOverviewAnalytics />}
    </>
  );
}

export default SiteHealthPage;

