import React from "react";
import useAppStore from "../../../../../../../../appStore";

const BadgeStepIcon = ({ active, count, size=30 }) => {

  const {whiteLabelProps} = useAppStore();

  return (
    <div style={{
      width: size,
      height: size,
      borderRadius: '50%',
      fontSize: size === 30 ? '' : '1.3rem',
      backgroundColor: active ? whiteLabelProps.primary_color : 'lightgray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer'
    }}>
      <b>{count}</b>
    </div>
  )


};

export default BadgeStepIcon;