import React from 'react';
import { Box, Typography } from "@mui/material";
import MediaRowSquares from "../../../../../../../../shared/components/media/media-viewers/photos/MediaRowSquares";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const WorkItemCardSubDetails = ({ workItem }) => {
  const formattedDate = format(new Date(workItem.create_date), 'MMM d, yyyy');
  const { t } = useTranslation();

  return (
    <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '15px' }}>
      {/* Left Column */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', padding: '16px', paddingBottom: 0, paddingTop: 0}}>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", marginRight: "10px" }}>
          <MediaRowSquares itemMedia={workItem.media} captions={false} size={55} />
        </Box>
      </Box>

      {/* Right Column */}
      <Box sx={{ textAlign: 'right', marginTop: '5px', padding: '16px', paddingBottom: 0, paddingTop: 0}}>
        <div className="d-flex flex-column"
             style={{
               maxWidth: "350px",
               overflow: "hidden",
               textOverflow: "ellipsis",
               whiteSpace: "nowrap",
             }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#555', fontSize: '1.2rem' }}>
            {workItem.created_by.person.first_name} {workItem.created_by.person.last_name}
          </Typography>
          <Typography variant="h4" color="textSecondary" sx={{ fontSize: '1.1rem', marginTop: '2px' }}>
            {t('created')} {formattedDate}
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default WorkItemCardSubDetails;
