import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { GroupEntrySerializer } from "../../../models/serializers/groupEntrySerializer";
import { Organization } from "../../../models/organization.model";
import { CaseTypeSerializer } from "../../../models/serializers/caseTypeSerializer";
import { CaseFormSerializer } from "../../../models/serializers/caseFormSerializer";

export class CommandGetDynamicForm {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl(id) {
    return `${this.SERVICE}/cases/types/${id}/forms/`;
  }

  async run(caseType) {
    const url = this.getUrl(caseType.id)
    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success')
    }

  }

  deserialize(data) {
    const serializer = new CaseFormSerializer();
    const form = serializer.deserialize(data)
    return form
  }

}

