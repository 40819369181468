import React, { useEffect, useState } from 'react';
import useAppStore from '../../../../../../appStore';
import { fetchMonthlyScores } from '../../../../../../shared/utils/data-fetch/analyticDataFetchHelper';
import CustomLoadingDots from '../../../../../../shared/components/page/popup-dialog/CustomLoadingDots';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';

const MonthScoreBarChart = ({ location_id, startDate = null, endDate = null }) => {
  const { whiteLabelProps, loggedInUser, selectedLocation } = useAppStore();
  const [chartData, setChartData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setError('');
    fetchMonthlyScores(loggedInUser.organization.id, location_id, startDate, endDate)
      .then((data) => {
        console.log('Fetched data:', data);
        setChartData(data);
      })
      .catch((error) => {
        setError(error instanceof Error ? error.message : String(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedLocation.id, loggedInUser.organization.id, startDate, endDate]);

  useEffect(() => {
    console.log('chartData:', chartData);
  }, [chartData]);

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!chartData || chartData.length === 0) {
    console.log('chartData is empty or null:', chartData);
    return <p>No data available</p>;
  }

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      id: 'monthly-scores',
      stacked: false,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: chartData.map((entry: any) => entry.month),
      labels: {
        formatter: (value) => dayjs(value).format('MMM YYYY'),
      },
    },
    yaxis: {
      title: {
        text: 'Score',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    colors: [whiteLabelProps.primary_color, '#08e68B', '#e8e8e8'],
    title: {
      text: 'Scores By Month',
      align: 'center',
    },
  };

  const series = [
    {
      name: 'Internal',
      data: chartData.map((entry: any) =>
        entry.internal_inspection_score ? parseFloat(entry.internal_inspection_score) : 0
      ),
    },
    {
      name: 'Joint',
      data: chartData.map((entry: any) =>
        entry.joint_inspection_score ? parseFloat(entry.joint_inspection_score) : 0
      ),
    },
    {
      name: 'Baseline',
      data: chartData.map((entry: any) =>
        entry.baseline_inspection_score ? parseFloat(entry.baseline_inspection_score) : 0
      ),
    },
  ];

  return (
    <Card variant="outlined" sx={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)' }}>
      <CardContent>
        <Typography component="h1" variant="h6" textAlign="center">
          Scores By Month
        </Typography>
        <Chart options={options} series={series} type="bar" height={350} />
      </CardContent>
    </Card>
  );
};

export default MonthScoreBarChart;

