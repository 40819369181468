import React from 'react';

const EmptyCard = () => {

  return (
    <div className='card' style={{ width: '150px', height: '145px' }}>
      <div className='card-body d-flex flex-center flex-column p-9'>
        {'--'}
      </div>
    </div>
  );
};

export default EmptyCard;



