import React from "react";
import ActionButton from "../../../../../../shared/components/buttons/ActionButton";
import CircleIcon from '@mui/icons-material/Circle';

const WhereAndWhatRow = (props) => {

  const {
    entry,
    onDelete,
    isMobile,
    isEditing
  } = props;

  const renderServiceItems = () => {
    if (entry.service_items && entry.service_items.length > 0) {
      return entry.service_items.map((item, index) => (
        <div key={index} className="d-flex align-items-start text-primary fst-italic fs-7">
          <CircleIcon color={'inherit'} sx={{ fontSize: '.6rem', mr: '5px', mt: '5px' }}/>
          <div>
            <span>{item.entity_type?.name || item.name}</span>
            {item.details && <div style={{ marginLeft: '20px' }}>{item.details}</div>}
          </div>
        </div>
      ));
    } else {
      return <span className="text-gray-500 fw-bold fs-7">No service items available</span>;
    }
  };



  return (
    <>
      <tr style={{ width: '100%', border: '1px solid #c7c7c7', alignItems: 'center'}}>
        <td style={{ maxWidth: isMobile ? '120px' : 'auto' }}>
          <div className="d-flex align-items-center ps-4">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold mb-1 fs-6">
                {entry.entity?.entity_path?.[0]?.name ? entry.entity.entity_path[0].name : 'N / A'}
              </a>
              <span
                className="text-gray-400 fw-semibold d-block fs-7">Floor {entry.entity?.name ? entry.entity?.name : ''} / {entry.entity_type.name ? entry.entity_type.name : ''}</span>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-start flex-column">
            {renderServiceItems()}
          </div>
        </td>

        {onDelete !== null && isEditing &&
          <td style={{maxWidth: isMobile ? '50px' : '20px'}}>
            <div className="d-flex justify-content-center flex-shrink-0 pe-4">
              <ActionButton iconType="trash" onClick={(e) => {
                e.preventDefault();
                onDelete();
              }} />
            </div>
          </td>
        }

      </tr>
    </>
  )
}

export default WhereAndWhatRow