import React, { useEffect, useState } from "react";
import SuppliesTable from "./components/SuppliesTable";
import { SupplyService } from "../../../../shared/services/supplies/supplyService";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { TextField } from "@mui/material";
import { TextFieldButton } from "../../../../shared/components/buttons/TextFieldButton";
import useAppStore from "../../../../appStore";
import { Supply } from "../../../../shared/models/supply.model";

const SuppliesList = (props) => {
  const {
    onEdit,
    readOnly=false
  } = props;

  const {masterRefresh} = useAppStore();
  const service = new SupplyService();
  const [error, setError] = useState("");
  const [supplies, setSupplies] = useState<Supply[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    service.getSupplies(1)
      .then(supplyData => {
        setSupplies(supplyData);
      })
      .catch(error => {
        setError(error instanceof Error ? error.message : String(error));
      });
  }, [masterRefresh]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSupplies = supplies
    .filter((supply: Supply) => supply.name)
    .filter((supply: Supply) =>
      supply.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  if (error) {
    return <CustomError error={error} />;
  }

  return (
    <>
      <div className="d-flex flex-row mt-5">
        <TextField
          variant="outlined"
          placeholder="Find..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            width: '90%',
            '& .MuiOutlinedInput-root': {
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              backgroundColor: 'white',
              height: '45px',
              '& .MuiInputBase-input': {
                height: '100%',
                padding: '16.5px 14px',
              },
            },
          }}
        />
        <TextFieldButton onClick={handleSearchChange}>
          Search
        </TextFieldButton>
      </div>
      <SuppliesTable supplies={filteredSupplies} onEdit={onEdit} readOnly={readOnly} />
    </>
  );
};

export default SuppliesList;
