import { CommandGetLanguages } from "./commands/cmdGetLanguages";

export class LanguageService {

  async getLanguages(provider_id: number | undefined) {
    const cmd = new CommandGetLanguages();
    const results = await cmd.run(provider_id);
    return results;
  }

}
