import React, { useEffect, useState } from "react";
import CourseRow from './CourseRow';
import { TrainingService } from "../../../../../shared/services/training/trainingService";
import { useAuth } from "../../../../auth";
import { Course } from "../../../../../shared/models/course.model";
import useAppStore from "../../../../../appStore";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";



const CourseListTable = (props) => {
    const {
        onEditCourseClicked,
        onDeleteCourseClicked,
        selectedCategory,
        readOnly=false
    } = props;

    const { loggedInUser } = useAppStore();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [courses, setCourses] = useState<Course[]>([]);

    useEffect(() => {
        const fetchCourses = async () => {
            setIsLoading(true);

            try {
                const service = new TrainingService();
                const courses = await service.getCourses(loggedInUser?.organization.id, selectedCategory.id);
                setCourses(courses);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchCourses().then();
    }, [selectedCategory]);


    return (
      <>
          {/*begin::Table container*/}
          <div className="table-responsive flex-row-fluid mt-5">

              {/*begin::Table*/}
              <table className="table table-row-solid align-middle gs-0 gy-3 my-0">
                  {/*begin::Table head*/}
                  <thead>
                  {courses.length > 0 &&
                    <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                        <th className="p-0 pb-3 min-w-225px text-start">{t('course')}</th>
                    </tr>
                  }
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody>
                  {courses.length === 0 &&
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%" textAlign="center" p={2}>
                        <div>
                            <p className='text-muted' style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>{t('no-courses-yet')}</p>
                        </div>
                    </Box>                  }
                  {courses.map((course) => (
                    <CourseRow
                      key={course.id}
                      course={course}
                      onEditCourseClicked={onEditCourseClicked}
                      onDeleteCourseClicked={onDeleteCourseClicked}
                      readOnly={readOnly}
                    />
                  ))}
                  </tbody>
                  {/*end::Table body*/}
              </table>
          </div>
          {/*end::Table*/}
      </>
    );


}

export default CourseListTable
