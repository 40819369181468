import React from "react";
import useInspectionsStore from "../../../../inspectionsStore";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import InspectionSpaces from "./components/InspectionSpaces";
import { Button } from "@mui/material";
import { format } from "date-fns";
import { getPacificTime } from "../../../../../../shared/utils/table-modifier-util/tableDataUtil";

const InspectionMobileCard = ({ inspection } ) => {

  const formattedDate = format(new Date(inspection.inspected_date), 'MMM d, yyyy');
  const {handleEditItem} = useInspectionsStore()

  const handleViewInspectionClicked = () => {
    if (handleEditItem === null) { return }
    handleEditItem(inspection);
  }

  return (
    <Card variant="outlined" sx={{marginTop: '15px', marginBottom: '15px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
      <CardContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* COLUMN 1 */}
          <div style={{ width: '65%', paddingTop: '10px', paddingBottom: '30px', paddingLeft:'10px' }}>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-start flex-column">
                <a className="text-gray-800 fw-bold mb-1 fs-6 pt-1">
                  {inspection.inspected_by.first_name} {inspection.inspected_by.last_name}
                </a>
                <span className="text-gray-400 fw-semibold d-block fs-7">Site Manager</span>
                <div className="d-flex align-items-center pt-5">
                  {formattedDate}
                </div>
                <div className="d-flex align-items-center">
                  at {getPacificTime(inspection.inspected_date)}
                </div>
              </div>
            </div>
          </div>

          {/* COLUMN 2 */}
          <div style={{ width: '35%', marginTop: '15px'}}>
            <div className="d-flex justify-content-end flex-column pe-3">
              <div className="badge py-3 fs-7 badge-light-success justify-content-center" >
                Score: {inspection.score}
              </div>
              <div className="badge py-3 fs-7 badge-light-primary justify-content-center mt-3" >
                Spaces: {inspection.inspected_entities_count}
              </div>
              <Button
                variant="contained"
                style={{ marginTop: 10, backgroundColor: '#F4F4F4', color: 'gray', maxHeight: '30px', boxShadow: 'none' }}
                onClick={(e) => {
                e.preventDefault();
                handleViewInspectionClicked();
              }}>
                <i className="fas fa-eye fs-5 me-1"></i>
                View
              </Button>
            </div>

          </div>
        </div>
        <InspectionSpaces inspection={inspection} />
      </CardContent>
    </Card>
  );

}

export default InspectionMobileCard