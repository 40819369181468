import React from 'react';
import { formatPhoneNumber } from "../../../utils/data-transform/dataUtil";
import { useMediaQuery, useTheme } from "@mui/material";

interface UserCardSmallProps {
  name: string;
  phone_number: string;
}

const UserCardSmall: React.FC<UserCardSmallProps> = ({ name, phone_number }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const chipStyle: React.CSSProperties = {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '0',
    paddingRight: '0',
    margin: '4px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
    cursor: 'default',
    width: '150px',
  };


  const nameStyle: React.CSSProperties = {
    fontWeight: 600,
    fontSize: '0.875rem',
    marginBottom: '4px',
  };

  const phoneStyle: React.CSSProperties = {
    color: '#0d6efd',
    fontSize: '0.75rem',
  };

  return (
    <div style={chipStyle}>
      <span style={nameStyle}>
        {name}
      </span>
      <span style={phoneStyle}>
        {formatPhoneNumber(phone_number)}
      </span>
    </div>
  );
};

export default UserCardSmall;