import React, { useState } from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem
} from "@mui/material";
import CustomDatePicker from "../../../../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";
import { AppDate } from "../../../../../../../shared/utils/date-time/AppDate";

const SafetyCaseForm = (props) => {
  const { caseToEdit, setCaseToEdit } = props;

  const [formData, setFormData] = useState({
    injury_illness_type: caseToEdit.form_data?.injury_illness_type || "",
    date_of_incident: caseToEdit.form_data?.date_of_incident || new AppDate().dateTime,
    time_of_incident: caseToEdit.form_data?.time_of_incident || "",
    incident_location: caseToEdit.form_data?.incident_location || "",
    was_emergency: caseToEdit.form_data?.was_emergency || "No",
    triage_line_called: caseToEdit.form_data?.triage_line_called || "No",
    medical_attention_recommended: caseToEdit.form_data?.medical_attention_recommended || "No",
    treatment_steps_given: caseToEdit.form_data?.treatment_steps_given || "",
    witnesses: caseToEdit.form_data?.witnesses || "",
    scene_safe: caseToEdit.form_data?.scene_safe || "No",
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    let parsedValue = value;

    const updatedFormData = { ...formData, [name]: parsedValue };
    setFormData(updatedFormData);

    setCaseToEdit({
      ...caseToEdit,
      form_data: updatedFormData,
    });
  };

  const handleDateChange = (newValue) => {
    const updatedFormData = { ...formData, incident_date: newValue };
    setFormData(updatedFormData);

    setCaseToEdit({
      ...caseToEdit,
      form_data: updatedFormData,
    });

  };

  return (
    <form>
      <div className="flex-column-fluid">

        {/* Injury / Illness Type */}
        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <span className="text-gray-700">Nature of Incident</span>
          <Select
            name="injury_illness_type"
            value={formData.injury_illness_type}
            onChange={handleChange}
          >
            <MenuItem value="Abrasion, Scrapes, Cut">Abrasion, Scrape, Cut</MenuItem>
            <MenuItem value="Sprain, Strain">Sprain, Strain</MenuItem>
            <MenuItem value="Burn">Burn</MenuItem>
            <MenuItem value="Pain">Pain</MenuItem>
            <MenuItem value="Nausea">Nausea</MenuItem>
            <MenuItem value="Headache, Dizziness">Headache, Dizziness</MenuItem>
            <MenuItem value="Difficulty Breathing">Difficulty Breathing</MenuItem>
            <MenuItem value="Slip, Trip, or Fall">Slip, Trip, or Fall</MenuItem>
            <MenuItem value="Heat Exhaustion">Heat Exhaustion</MenuItem>
            <MenuItem value="Drug Use">Drug Use</MenuItem>
            <MenuItem value="Eye / Vision">Eye / Vision</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>


        <FormControl component="fieldset" style={{ marginBottom: 15, width: '100%' }}>
          <span className="text-gray-700">Date of Incident</span>
          <div>
            <CustomDatePicker value={formData.date_of_incident} onChange={handleDateChange} quickPick={false} />
          </div>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <span className="text-gray-700">Time of Incident</span>
          <TextField
            name="time_of_incident"
            value={formData.time_of_incident}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl component="fieldset" style={{ marginBottom: 15, width: "100%" }}>
          <span className="text-gray-700">Is the scene currently safe and can others on your team continue to work?</span>
          <RadioGroup
            row
            name="scene_safe"
            value={formData.scene_safe.toString()}
            onChange={handleChange}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>

        </FormControl>

        <FormControl component="fieldset" style={{ marginBottom: 15 }}>
          <span className="text-gray-700">Was this a Life-Threatening Emergency where 911 or Professional Medical Attention was given?</span>
          <RadioGroup
            row
            name="was_emergency"
            value={formData.was_emergency.toString()}
            sx={{ columnGap: 2 }}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" style={{ marginBottom: 15, width: "100%" }}>
          <span className="text-gray-700">Did you call the Nurse Triage Line at 1-844-945-5951?</span>
          <RadioGroup
            row
            name="triage_line_called"
            value={formData.triage_line_called.toString()}
            sx={{ columnGap: 2 }}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset" style={{ marginBottom: 15, width: "100%" }}>
          <span className="text-gray-700">Did the Nurse recommend professional medical attention?</span>
          <RadioGroup
            row
            name="medical_attention_recommended"
            value={formData.medical_attention_recommended.toString()}
            sx={{ columnGap: 2 }}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <span className="text-gray-700">What were the next treatment steps given by the Nurse?</span>
          <TextField
            name="treatment_steps_given"
            multiline
            rows={4}
            value={formData.treatment_steps_given}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <span className="text-gray-700">Names of witnesses (if any):</span>
          <TextField
            name="witnesses"
            multiline
            rows={2}
            value={formData.witnesses}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: 15 }}>
          <span className="text-gray-700">Where, exactly, did the incident happen?</span>
          <TextField
            name="incident_location"
            multiline
            rows={2}
            value={formData.incident_location}
            onChange={handleChange}
          />
        </FormControl>

      </div>
    </form>
  );
};

export default SafetyCaseForm;
