import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { CaseSerializer } from "../../../models/serializers/caseSerializer";

interface RunParams {
  provider_id: number;
  employee_id?: number | null;
  location_id?: number | undefined;
  status?: string | null;
  case_type?: number | null;
  limit?: number | null;
  offset?: number | null;
}

export class CommandGetCases {
  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/cases/`;
  }

  async run({ provider_id, employee_id = null, location_id = undefined, status = null, case_type=null, limit = null, offset = null }: RunParams) {
    if (!provider_id) {
      throw new Error("Provider ID is required");
    }

    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        ...(status && { status: status }),
        ...(location_id && { location: location_id }),
        ...(case_type && { case_type: case_type }),
        ...(employee_id && { created_by: employee_id }),
        ...(limit && { limit: limit }),
        ...(offset && { offset: offset }),
      },
    };

    const tracer = useTracer.getState();
    tracer.trace('Cases', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success');
    }
  }

  deserialize(data: any) {
    const serializer = new CaseSerializer();
    const cases = serializer.deserializeList(data);
    return cases;
  }
}