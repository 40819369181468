import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";


export class CommandGetCaseTypeCounts {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/analytics/`;
  }

  async run(provider_id, location_id) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        module: 'cases',
        charts: 'type_counts',
        location: location_id
      },
    };

    const tracer = useTracer.getState();
    tracer.trace('WorkItems', 'Api', `GET ${url}`);

    try {
      const response = await httpClient.get(url, params);
      return response.data;
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`);
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success');
    }
  }

}