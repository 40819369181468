export class CaseForm {
  id: number;
  name: string;
  description: string;
  form: DynamicForm;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.form = new DynamicForm();
  }
}

export class DynamicForm {
  id: number;
  name: string;
  description: string;
  sections: Section[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.sections = [];
  }
}

export class Section {
  id: number;
  name: string;
  sage_prompt: string;
  fields: Field[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.sage_prompt = '';
    this.fields = [];
  }
}

export class Field {
  id: number;
  name: string;
  type: string;
  data_source: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.type = '';
    this.data_source = '';
  }
}
