import { BaseSerializer } from "./baseSerializer";
import { SurveySummary } from "../surveySummary.model";

export class SurveyLocationListSerializer extends BaseSerializer {
  public deserialize(data): any {
    const summaryData = data;
    const summary = new SurveySummary();

    if (summaryData) {
      summary.avg_score = summaryData.avg_score !== null ? parseFloat(summaryData.avg_score) : 0;
      summary.sent_count = summaryData.sent_count;
      summary.received_count = summaryData.received_count;
      summary.last_received = summaryData.last_received;
      summary.name = summaryData.name;
    }

    return summary;
  }
}