import React from 'react';
import { FormControl } from "@mui/material";
import CustomDatePicker from "../../../../../../shared/components/forms/date-time/date-picker/CustomDatePicker";

const DateField = ({ label, value, onChange }) => {
  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <span className="text-gray-700">{label}</span>
      <CustomDatePicker value={value} onChange={onChange} quickPick={false} />
    </FormControl>
  );
};

export default DateField;
