import React, { useEffect, useState } from "react";
import { Box, Card, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import ScoreTable from "../../../../../../shared/components/page/tables/ScoreTable";
import useAppStore from "../../../../../../appStore";
import { AnalyticsService } from "../../../../../../shared/services/analytics/analyticsService";
import { Question } from "../../../../../../shared/models/question.model";
import { Respondent } from "../../../../../../shared/models/respondent.model";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CommentsTable from "../../components/CommentsTable";

const EmployeeSurveyTable = ({ data }) => {

  const service = new AnalyticsService()
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {loggedInUser,whiteLabelProps,selectedLocation} = useAppStore();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [respondents, setRespondents] = useState<Respondent[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const respondentsData = await service.getRespondents(loggedInUser.organization, selectedLocation.id, undefined, 'ESat');
        const questionsData = await service.getQuestions(loggedInUser.organization, selectedLocation.id, undefined, 'ESat');
        const sortedRespondents = respondentsData.sort((a, b) => a.avg_score - b.avg_score);
        const sortedQuestions = questionsData.sort((a, b) => a.avg_score - b.avg_score);
        setRespondents(sortedRespondents);
        setQuestions(sortedQuestions);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

      fetchData().then();

  }, [loggedInUser, selectedLocation.id]);

  return (
    <>
      <div className='card card-flush h-md-100 mt-5 mb-10' style={{minHeight: '400px', boxShadow: '0 1px 2px rgba(0,0,0,0.1)'}}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>

          {!isMobile &&
            <Divider flexItem style={{ backgroundColor: "#e8e8e8" }} />
          }

          <div style={{ width: '100%' }}>
              <div>
                <Card sx={{ boxShadow: 'none', border: '1px solid #e8e8e8', borderTopLeftRadius: '0px' }}>
                  {isMobile &&
                    <Box className="d-flex flex-row justify-content-between align-items-center"
                         sx={{ padding: 2, backgroundColor: "primary.main", color: "primary.contrastText" }}>
                      <div className='d-flex flex-row align-items-center'>
                        <Typography variant="h6" component="div" sx={{ marginLeft: '5px', fontWeight: 'bold' }}>
                          Employee Survey Results
                        </Typography>
                      </div>
                    </Box>
                  }

                  {isLoading ? (
                    <CustomLoadingDots />
                  ) : (
                    <div style={{ padding: "20px" }}>
                      <ScoreTable title="Question Averages" data={questions} columns={["question", "avg_score"]} />
                      <CommentsTable title="Respondent Comments" data={respondents} />
                    </div>
                  )}
                </Card>
              </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default EmployeeSurveyTable