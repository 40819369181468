import React from 'react';
import { Dialog, Box, AppBar, IconButton, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import ModalTransition from "../../page/transitions/ModalTransition";

const PdfViewer = ({ open, url, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        console.log("Dialog onClose triggered");
        onClose();
      }}
      fullScreen
      TransitionComponent={ModalTransition}
      PaperProps={{
        sx: {
          bgcolor: 'background.paper',
          boxShadow: 2,
          borderRadius: 1,
          animation: 'fadeIn 0.3s',
          '@keyframes fadeIn': {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
        }
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          overflow: 'hidden'
        }}
      >
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
          <Viewer fileUrl={url} />
        </Worker>
      </Box>
    </Dialog>
  );
};

export default PdfViewer;

