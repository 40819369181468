import React, { useEffect, useState } from "react";
import EmployeeTable from "./components/EmployeeTable";
import useAppStore from "../../../../appStore";
import { EmployeeService } from "../../../../shared/services/employee/employeeService";
import LoadMoreButton from "../../../../shared/components/buttons/LoadMoreButton";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { Tab, Tabs, TextField } from "@mui/material";
import { TextFieldButton } from "../../../../shared/components/buttons/TextFieldButton";
import { Employee } from "../../../../shared/models/employee.model";

const EmployeeList = () => {

    const { selectedLocation, loggedInUser, masterRefresh } = useAppStore();
    const canEdit = loggedInUser?.permissions.hasAccess("Employees", 15);
    const [isLoading, setIsLoading] = useState(false);
    const [tab, setTab] = React.useState(0);
    const [error, setError] = useState("");
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [searchResult, setSearchResult] = useState<Employee[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchFor, setSearchFor] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);

    const fetchEmployees = async (page: number) => {
        setIsLoading(true);
        try {
            const service = new EmployeeService();
            const offset = page * 10;
            if (page === 0) {setEmployees([])}

            const newEmployees = await service.getEmployees(
              loggedInUser.organization.id,
              tab === 1 ? undefined : selectedLocation.id,
              offset,
              10
            );

            setEmployees(prev => page === 0 ? newEmployees : [...prev, ...newEmployees]);
            setShowLoadMore(newEmployees.length === 10);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchSearchResult = async () => {
            setIsLoading(true);
            try {
                const service = new EmployeeService();
                const employees = await service.searchEmployees(loggedInUser.organization.id, searchFor);
                setSearchResult(employees);
                setShowLoadMore(false);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (searchFor !== '') {
            fetchSearchResult().then();
        } else {
            fetchEmployees(0).then();
        }

    }, [searchFor, loggedInUser.organization.id]);

    useEffect(() => {
        setEmployees([]);
        setCurrentPage(0);
        if (selectedLocation.id !== 0) {
            fetchEmployees(0).then();
        }
    }, [selectedLocation.id, tab, masterRefresh]);

    const handleShowMore = () => {
        const nextPage = currentPage + 1;
        fetchEmployees(nextPage).then();
        setCurrentPage(nextPage);
    };

    const handleSearch = () => {
        setSearchFor(searchQuery);
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    if (error) {
        return <CustomError error={error} />;
    }

    return (
      <>
          <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="Selected Location" value={0} />
              <Tab label="All Locations" value={1} />
          </Tabs>
          <form onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
          }}>
              <div className="d-flex flex-row mt-5">
                  <TextField
                    variant="outlined"
                    placeholder="Find employees..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                        width: '90%',
                        '& .MuiOutlinedInput-root': {
                            background: 'white',
                            borderTopLeftRadius: '8px',
                            borderBottomLeftRadius: '8px',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            borderRight: 'none',
                            height: '45px',
                            '& .MuiInputBase-input': {
                                height: '100%',
                                padding: '16.5px 14px',
                            },
                        },
                    }}
                  />
                  <TextFieldButton type="submit">
                      Search
                  </TextFieldButton>
              </div>
          </form>

          {(!isLoading || currentPage !== 0) &&
            <EmployeeTable employees={searchFor !== '' ? searchResult : employees} canEdit={canEdit} />}
          {(showLoadMore || isLoading) && <LoadMoreButton onClick={handleShowMore} isLoading={isLoading} />}
      </>
    );
};

export default EmployeeList;
