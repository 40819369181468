
export class Media {

    id: number;
    workitem_status: any;
    file_name: string;
    url: string;
    width: number;
    height: number;
    type: string;
    language: string | null;


    constructor() {
        this.id = 0;
        this.file_name = '';
        this.url = '';
        this.width = 100;
        this.height = 100;
        this.workitem_status = null;
        this.type = 'default';
        this.language = null;
    }

}

