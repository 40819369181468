import React from "react";
import Scoring from "../../edit-inspected-entity/components/scoring/Scoring";
import { useEditInspectedEntity } from "../../edit-inspected-entity/useEditInspectedEntity";
import SpaceSelection from "../../edit-inspected-entity/components/space-selection/SpaceSelection";
import InspectionWorkItem from "../../edit-inspected-entity/components/inspection-work-item/InspectionWorkItem";
import useAppStore from "../../../../../../../appStore";

const AssistedInspectionStepContents = (props) => {
  const { appImages } = useAppStore();
  const { step, workItemStatus, handleWorkItemAction } = props;
  const { inspectedEntity } = useEditInspectedEntity();
  const success = workItemStatus === 1;

  return (
    <div className="flex-column-fluid">
      {step === 0 && (
        <div>
          <SpaceSelection />
        </div>
      )}

      {step === 1 && (
        <div>
          <Scoring />
        </div>
      )}

      {step === 2 && <div></div>}

      {step === 3 && (
        <InspectionWorkItem
          onActionClicked={handleWorkItemAction}
          inspectedEntity={inspectedEntity}
        />
      )}

      {step === 4 && (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: "100px", padding: "65px" }}
        >
          <img
            src={!success ? appImages.iconItemCancelled : appImages.iconWorkDone}
            style={{ width: 120, height: !success ? 150 : 120 }}
          />
          <h3 style={{ textAlign: "center", width: "100%" }}>
            {success
              ? "Work Item Created. Your inspection has been saved."
              : "Work Item Cancelled. Your inspection has been saved."}
          </h3>
        </div>
      )}
    </div>
  );
};

export default AssistedInspectionStepContents;

