import React from 'react';
import { TextField } from '@mui/material';

interface FreeTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  multiline?: boolean;
  rows?: number;
}

const TextAreaField: React.FC<FreeTextFieldProps> = ({ value, onChange, label, multiline = false, rows = 1 }) => {
  return (
    <div style={{ marginBottom: 15 }}>
      <span className="text-gray-700">{label}</span>
      <TextField
        name={label}
        value={value}
        onChange={onChange}
        fullWidth
        multiline={multiline}
        rows={rows}
      />
    </div>
  );
};

export default TextAreaField;
