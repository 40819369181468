import React from 'react';
import useCasesStore from "../../../../caseStore";
import CaseReadOnly from "../../../edit-case/components/details/read-only/CaseReadOnly";
import Box from "@mui/material/Box";

const SageCaseSummary = () => {
  const { caseToEdit } = useCasesStore();

  return (
    <Box sx={{padding: '10px', margin: '1px', backgroundColor: '#f8f8f8', boxShadow: '1px 1px 5px rgba(0,0,0,0.2)', borderRadius: '5px'}}>
      <CaseReadOnly caseToEdit={caseToEdit} headers={false}/>
    </Box>
  );
};

export default SageCaseSummary;

