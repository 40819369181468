import React from 'react';
import { Button } from "@mui/material";
import useAppStore from "../../../appStore";
import { ThreeDots } from "react-loader-spinner";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LoadMoreButton = ({ onClick, isLoading }) => {

  const {whiteLabelProps} = useAppStore();

  const goToBottom = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    window.scrollTo(0, scrollHeight);
  };

  const handleLoadMore = async (event) => {
    event.preventDefault();
    onClick();
    goToBottom();
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row"
    }}>
      <Button
        variant="text"
        sx={{
          padding: '15px',
          marginBottom: '20px',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          color: whiteLabelProps.primary_color,
          textTransform: 'none',
          cursor: 'pointer',
          '&:hover': {
            color: whiteLabelProps.primary_color_light,
          },
        }}
        onClick={handleLoadMore}
      >
        {isLoading ? (
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color={whiteLabelProps.primary_color}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <>
            <ExpandMoreIcon /> Show More
          </>
        )}
      </Button>
    </div>
  );

}

export default LoadMoreButton;
