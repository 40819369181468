import React from 'react';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const RadioButtonField = ({ label, value, onChange }) => {
  return (
    <FormControl component="fieldset" fullWidth style={{ marginBottom: 15 }}>
      <span className="text-gray-700">{label}</span>
      <RadioGroup
        row
        name="custom-radio"
        value={value}
        sx={{ columnGap: 2 }}
        onChange={onChange}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonField;
