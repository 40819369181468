import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const DivToPdf = ({ contentId }) => {

  const {t} = useTranslation();

  const handlePrint = () => {
    const printContent = document.getElementById(contentId);

    if (!printContent) {
      console.error("Print content not found");
      return;
    }

    const printWindow = window.open("", "_blank", "width=800,height=600");
    if (printWindow) {
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join("\n");
          } catch (e) {
            console.warn('Could not load some styles:', e);
            return '';
          }
        })
        .join("\n");

      printWindow.document.write(`
      <html>
      <head>
        <title>Inspection PDF</title>
        <style>
          ${styles}
        </style>
      </head>
      <body>
        ${printContent.outerHTML}
      </body>
      </html>
    `);

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Unable to open print window");
    }
  };


  return (
    <Button
      color="inherit"
      variant="contained"
      onClick={handlePrint}
      sx={{ ml: 'auto', color: '#444444', mr: '3px' }}
    >
      {t('download-print')}
    </Button>
  );
};

export default DivToPdf;

