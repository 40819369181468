import React from "react";
import {Avatar, Chip} from "@mui/material";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import useAppStore from "../../../../../appStore";

const CourseRow = (props) => {

    const {
        course,
        onEditCourseClicked,
        onDeleteCourseClicked,
        readOnly=false
    } = props;

    const { appImages } = useAppStore();

    const handleDeleteCourse = () =>{
        onDeleteCourseClicked(course)
    }



    return (
        <>
            <tr>
                <td style={{width: '75%'}}>
                    <div className="d-flex align-items-center">
                        <img src={appImages.iconTraining}
                             style={{ width: "30px", height: "30px", marginRight: '10px' }} />
                        <div className="d-flex justify-content-start flex-column">
                            <a className="text-gray-800 fw-bold mb-1 fs-6">{course.name}</a>
                        </div>
                    </div>
                </td>
                <td style={{width: '25%'}}>
                    <div className="d-flex justify-content-end flex-shrink-0">
                        <ActionButton iconType={readOnly ? 'view' : 'pencil'} onClick={(e) => {
                            e.preventDefault();
                            onEditCourseClicked(course);
                        }} />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default CourseRow