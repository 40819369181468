import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

interface DuotoneCustomIconProps {
  photo: string;
  photoWidth: string;
  photoHeight: string;
  colorClass: string;
  itemNumber?: number;
}

const DuotoneCustomIcon: React.FC<DuotoneCustomIconProps> = ({
                                                               photo,
                                                               photoWidth,
                                                               photoHeight,
                                                               colorClass,
                                                               itemNumber
                                                             }) => {


  return (
    <div className={`symbol ${colorClass}`} style={{
      width: photoWidth,
      height: photoHeight,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
      <img src={photo} alt="Custom Icon" style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }} />
    </div>
  );
};

export default DuotoneCustomIcon;
