import { CommandDeleteWorkItem } from "./commands/cmdDeleteWorkItem";
import { CommandUpdateWorkItem } from "./commands/cmdUpdateWorkItem";
import { CommandGetWorkItemPrefill } from "./commands/cmdGetWorkItemPrefill";
import { CommandGetWorkItemsByEmployee } from "./commands/cmdGetWorkItemsByEmployee";
import { CommandGetWorkItems } from "./commands/cmdGetWorkItems";
import { CommandCreateWorkItem } from "./commands/cmdCreateWorkItem";
import { CommandGetWorkItemDetails } from "./commands/cmdGetWorkItemDetails";
import { CommandGetStatusCounts } from "./commands/cmdGetStatusCounts";
import { CommandCreateWorkItemPrefill } from "./commands/cmdCreateWorkItemPrefill";

export class WorkItemsService {

  async getWorkItems(provider_id: number | undefined, location_id: number | null, activeStep?: number | null, limit = Number.MAX_SAFE_INTEGER, offset=0) {
    const cmd = new CommandGetWorkItems();
    let results = await cmd.run(provider_id, location_id, activeStep, limit, offset);
    return results;
  }

  async getWorkItemDetails(workItemId) {
    const cmd = new CommandGetWorkItemDetails();
    const results = cmd.run(workItemId)
    return results
  }

  async getWorkItemsForEmployee(provider_id: number | undefined, employee_id: number | undefined, status) {
    const cmd = new CommandGetWorkItemsByEmployee();
    const results = await cmd.run(provider_id, employee_id, status);
    return results;
  }

  async createWorkItem(workItem) {
    const cmd = new CommandCreateWorkItem()
    const results = cmd.run(workItem)
    return results
  }

  async createWorkItemPrefill(source) {
    const cmd = new CommandCreateWorkItemPrefill()
    const results = cmd.run(source)
    return results
  }

  async updateWorkItem(workItem) {
    const cmd = new CommandUpdateWorkItem();
    const updatedEmployee = await cmd.run(workItem);
    return updatedEmployee;
  }

  async deleteWorkItem(workItemId) {
    const cmd = new CommandDeleteWorkItem();
    const result = await cmd.run(workItemId);
    return result;
  }

  async getPrefill(inspected_entity_id: number) {
    const cmd = new CommandGetWorkItemPrefill();
    const results = cmd.run(inspected_entity_id)
    return results
  }

  async getWorkItemStatusCounts(provider_id, location_id) {
    const cmd = new CommandGetStatusCounts();
    const results = await cmd.run(provider_id, location_id);
    return results;
  }

}
