import React, { useEffect, useState } from "react";
import ServiceItemRow from "./ServiceItemRow";
import { Button } from "@mui/material";
import CustomLoadingDots from "../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { EntityTypeService } from "../../../../../../../shared/services/entity-types/entityTypeService";
import { ConfirmationDialog } from "../../../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import { ServiceItem } from "../../../../../../../shared/models/serviceItem.model";
import ServiceItemSelector from "./components/ServiceItemSelector";
import { ServicePlanService } from "../../../../../../../shared/services/service-plans/servicePlanService";
import useAppStore from "../../../../../../../appStore";

const ServiceItemTable = (props) => {
    const {
        spaceType,
        setSpaceType,
        global=false,
        ...other } = props;

    const [serviceItems, setServiceItems] = useState<any[]>([]);
    const [selectedServiceItem, setSelectedServiceItem] = useState<ServiceItem | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [selectorOpen, setSelectorOpen] = useState(false);
    const existing = spaceType && spaceType.id !== 0;
    const {selectedLocation, masterRefresh, toggleMasterRefresh} = useAppStore();

    useEffect(() => {
        const fetchSpaceDetails = async () => {
            try {
                setIsLoading(true);
                const service = new EntityTypeService();
                const type = await service.getEntityType(spaceType?.id, global ? 0 : selectedLocation.id);
                setSpaceType(type);
                setServiceItems(type.service_plan.items)
                console.log(type)
            } catch (error) {
                console.error('Failed to fetch service items:', error);
            } finally {
                setIsLoading(false);
            }
        }

        if (spaceType && spaceType.id !== 0) {
            fetchSpaceDetails().then();
        } else {
            setServiceItems([])
        }
    }, [spaceType?.id, masterRefresh, selectedLocation]);


    const handleNewServiceItem = () => {
        setSelectorOpen(true);
    }

    const handleRemoveConfirmed = async () => {
        const updated = serviceItems.filter(s => s?.id !== selectedServiceItem?.id);
        setServiceItems(updated);
        await handleDelete(selectedServiceItem);
        setConfirmationOpen(false);
        setSelectedServiceItem(null);
    };

    const handleRemoveServiceItem = (item) => {
        setSelectedServiceItem(item)
        setConfirmationOpen(true);
    };


    const handleDelete = async (item) => {
        try {
            if (spaceType?.service_plan?.id) {
                await new ServicePlanService().deleteServicePlanItem(spaceType.service_plan, item);
            }
        } catch (e) {
            console.error('Error in delete:', e);
        }
    }

    const handleAddNewServiceItems = async (newServiceItems) => {
        if (!serviceItems || serviceItems?.length === 0) {
            setServiceItems(newServiceItems);
        } else {
            setServiceItems(prevItems => [...prevItems, ...newServiceItems]);
        }

        for (const item of newServiceItems) {
            try {
                if (spaceType?.service_plan?.id) {
                    await new ServicePlanService().postServicePlanItem(spaceType.service_plan, item, !global ? selectedLocation.id : null);
                }
            } catch (e) {
                console.error('Error in handleSubmit:', e);
            }
        }

        toggleMasterRefresh();
    };


    const handleEditSchedule = async (item) => {
        try {
            if (spaceType?.service_plan?.id) {
                await new ServicePlanService().updateItemFrequency(spaceType.service_plan, item, item.frequency);
            }
        } catch (e) {
            console.error('Error in handleSubmit:', e);
        } finally {
            toggleMasterRefresh();
        }
    };

    if (isLoading) {
        return <CustomLoadingDots />;
    }

    return (
      <>
          {serviceItems && serviceItems.length > 0 ? (
            <div className="card card-flush h-md-100 mt-5 border-bottom-0" style={{marginLeft: '15px', minHeight: '600px'}}>
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">SERVICE PLAN</span>
                    </h3>
                    {global &&
                      <div className="card-toolbar">
                          <Button color="primary" onClick={handleNewServiceItem}
                                  style={{ height: "35px" }}>
                              + PLAN ITEM
                          </Button>
                      </div>
                    }
                </div>
                <div className="card-body pt-6">
                    <div className="table-responsive border-bottom-0">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                        <thead>
                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                <th className="p-0 pb-3 min-w-225px text-start">SERVICE PLAN ITEMS</th>
                                <th className="p-0 pb-3 min-w-125px text-end">DAILY</th>
                                <th className="p-0 pb-3 min-w-75px text-end">WEEKLY</th>
                                <th className="p-0 pb-3 min-w-75px text-end">MONTHLY</th>
                                <th className="p-0 pb-3 pe-3 min-w-75px text-end">YEARLY</th>
                                <th className="p-0 pb-3 min-w-25px text-center">ACTIONS</th>
                            </tr>
                            </thead>
                            <tbody>
                            {serviceItems.map((item, index) => (
                              <ServiceItemRow
                                key={index}
                                item={item}
                                onSchedule={handleEditSchedule}
                                onRemove={handleRemoveServiceItem}
                                global={global}
                              />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "50px" }}>
                <div>
                    <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                        {existing ? 'No Service Items Yet!' : ''}
                    </p>
                    {existing &&
                      <Button color="primary" onClick={handleNewServiceItem} style={{ height: '35px' }}>
                          + New Service Item
                      </Button>
                    }
                </div>
            </div>
          )}
          {confirmationOpen && (
            <ConfirmationDialog
              isOpen={confirmationOpen}
              setIsOpen={setConfirmationOpen}
              message="Delete Service Item?"
              description={`Are you sure you want to delete this service item? This action cannot be undone.`}
              onConfirmClicked={handleRemoveConfirmed}
            />
          )}
          <ServiceItemSelector open={selectorOpen} onClose={() => setSelectorOpen(false)} onSubmit={handleAddNewServiceItems} />
      </>
    )
}

export default ServiceItemTable
