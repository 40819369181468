import React from "react";
import PositionRow from "./PositionRow";



const PositionTable = (props) => {

  const{ positions, } = props

  return (
    <>
      {/* Begin Table Widget */}
      <div className="card card-flush h-md-100 mt-5">
        {/* Begin Card Body */}
        <div className="card-body pt-6">
          {/* Begin Table Container */}
          <div className="table-responsive">
            {/* Begin Table */}
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              {/* Begin Table Head */}
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                <th className="p-0 pb-3 min-w-225px text-start">POSITION TITLE</th>
              </tr>
              </thead>
              {/* End Table Head */}
              {/* Begin Table Body */}
              <tbody>
              {positions.map((item) => (
                <PositionRow key={item.id}
                             item={item}
                             onEdit={null}
                             onDelete={null}
                />
              ))}
              </tbody>
              {/* End Table Body */}
            </table>
            {/* End Table */}
          </div>
          {/* End Table Container */}
        </div>
        {/* End Card Body */}
      </div>
      {/* End Table Widget */}
    </>
  );

}

export default PositionTable