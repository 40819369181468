import React, { useState } from "react";
import { Popover, Box } from "@mui/material";
import CustomDatePicker from "./CustomDatePicker";
import { DateTime } from 'luxon';
import useAppStore from "../../../../../appStore";
import { useTranslation } from "react-i18next";

const DatePicker = ({ workItemToEdit, onDateChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const {whiteLabelProps} = useAppStore();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dueInDays = workItemToEdit.due_date
    ? DateTime.fromISO(workItemToEdit.due_date).diff(DateTime.now(), 'days').days
    : 30;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleOpen} style={{ cursor: 'pointer', width: '100%', marginTop: '10px' }}>
          <div className="d-flex flex-row justify-content-center p-4" style={{ width: '100%', borderRadius: '5px', border: '1px solid #c7c7c7' }}>
            <div className="fw-bold fs-6 me-3" style={{textDecoration: 'underline', color: whiteLabelProps.primary_color}}>{!workItemToEdit.is_overdue ? `${t('due-in')}: ` : `Overdue: `} </div>
            <div className="text-gray-800 fw-bolder fs-6">{!workItemToEdit.is_overdue ? `${Math.round(dueInDays)} days` : `${workItemToEdit.time_overdue}`}</div>
          </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <CustomDatePicker
            value={workItemToEdit.due_date}
            onChange={(newValue) => {
              onDateChange(newValue);
              handleClose();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default DatePicker;
