import { BaseSerializer } from "./baseSerializer";
import { DynamicForm } from "../caseForm.model";
import { SectionSerializer } from "./sectionSerializer";

export class DynamicFormSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let form = new DynamicForm();

    this._copyAttributes(form, data);

    if (Array.isArray(data.sections)) {
      let sectionSerializer = new SectionSerializer();
      form.sections = data.sections.map(data => sectionSerializer.deserialize(data));
    }

    return form;

  }

}