import { CommandGetContacts } from "./commands/cmdGetContacts";
import { CommandCreateContact } from "./commands/cmdCreateContact";
import { CommandGetContactTiers } from "./commands/cmdGetContactTiers";
import { CommandUpdateContact } from "./commands/cmdUpdateContact";
import { CommandDeleteContact } from "./commands/cmdDeleteContact";


export class ContactService {
  async getContacts(location_id) {
    const cmd = new CommandGetContacts();
    const results = await cmd.run(location_id);
    return results;
  }

  async createContact(contact) {
    const cmd = new CommandCreateContact()
    const results = cmd.run(contact)
    return results
  }

  async deleteContact(id) {
    const cmd = new CommandDeleteContact();
    const results = cmd.run(id)
    return results
  }

  async getTiers(provider) {
    const cmd = new CommandGetContactTiers();
    const results = cmd.run(provider)
    return results
  }

  async updateContact(contact) {
    const cmd = new CommandUpdateContact();
    const results = cmd.run(contact)
    return results
  }
}
