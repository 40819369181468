
export class Respondent {
  recipient_emp_id: number;
  name: string;
  avg_score: number;
  comments: string;

  constructor() {
    this.recipient_emp_id = 0;
    this.name = "";
    this.avg_score = 0.0;
    this.comments = "";

  }

}