import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { SurveySummarySerializer } from "../../../models/serializers/surveySummarySerializer";
import { Organization } from "../../../models/organization.model";
import { LocationModel } from "../../../models/location.model";
import { JointSummarySerializer } from "../../../models/serializers/JointSummarySerializer";

export class CommandGetJointInspectionSummary {

    INSPECTIONS_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return `${this.INSPECTIONS_SERVICE}/analytics/`;
    }

    async run(organization: Organization, location: LocationModel, fromDate=undefined, toDate=undefined) {

        const url = this.getUrl()

        const params = {
            params: {
                provider: organization.id,
                location: location.id,
                module: 'inspections',
                charts: 'joint_inspection_summary',
                from_date: fromDate ? fromDate : undefined,
                to_date: toDate ? toDate : undefined
            }
        }

        const tracer = useTracer.getState()

        tracer.trace('Analytics', 'Api', `GET ${url}`)

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            tracer.trace('Analytics', 'Error', `${error}`)
            console.error('Error fetching survey summary:', error);
            throw error;
        } finally {
            tracer.trace('Analytics', 'Api', 'Success')
        }

    }

    deserialize(data) {

        const serializer = new JointSummarySerializer()
        const summary = serializer.deserialize(data)

        return summary
    }

}

