import { Media } from "./media.model";
import { TrainingCategory } from "./training-category.model";
import { Organization } from "./organization.model";

export class Course {
    id: number;
    name: string;
    description: string;
    categories: TrainingCategory[];
    status: string;
    category_id: number;
    organization: Organization;
    html_content: string;
    media: Media[];

    constructor() {
        this.id = 0;
        this.name = '';
        this.description = '';
        this.categories = [];
        this.status = '';
        this.category_id = 0;
        this.organization = new Organization();
        this.html_content = '';
        this.media = [];
    }
}


