import React, { useEffect } from "react";
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import {
    Alert,
    AppBar, Box, Button,
    Dialog,
    DialogActions,
    IconButton,
    Typography
} from "@mui/material";
import SpaceSelection from "./components/space-selection/SpaceSelection";
import Scoring from "./components/scoring/Scoring"
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import { useEditInspectedEntity } from "./useEditInspectedEntity";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";

const EditInspectedEntity = (props) => {

    const {
        open,
        onActionClicked,
    } = props

    const {
        scrollableContainerRef,
        isLoading, error,
        activeStep, setActiveStep,
        isEditing, setIsEditing,
        validationInfo,
        isPrevDisabled, isNextDisabled, isSubmitDisabled,
        inspectedEntity,
        disableButtons,
        handlePrevClicked, handleNextClicked, handleSubmitClicked, goToStep
    } = useEditInspectedEntity();

    useEffect(() => {
        setIsEditing(true);
        disableButtons(1);
        setActiveStep(1);
    }, [inspectedEntity.id]);


    if (isLoading) {
        return <CustomLoadingDots />;
    }
    if (error) {
        return <CustomError error={error} />;
    }

    const handleClose = () => {
        onActionClicked();
        goToStep(0)
        validationInfo.passed = true
        validationInfo.messages = []
    }

    return (

      <>
          <Dialog
            open={open}
            onClose={onActionClicked}
            TransitionComponent={ModalTransition}
          >
              <AppBar sx={{ position: 'relative' }}>
                  <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onActionClicked}
                        aria-label="close"
                      >
                          <CloseIcon />
                      </IconButton>
                      <Typography sx={{ flex: 1 }} variant="h5" component="div">
                          Inspect
                      </Typography>
                      <Button color="inherit" variant="contained" onClick={handleClose} sx={{ color: '#444444' }}>
                          Exit
                      </Button>
                  </Toolbar>
              </AppBar>

              { !validationInfo.passed && (

                <Alert className="mt-5 mx-5"
                       variant="outlined"
                       severity="error"
                >
                    {validationInfo.messages.map((msg, index) => (
                      <>
                          <Box component="label" sx={{ fontSize: '16px' }}>
                              {msg}
                          </Box>
                          <br/>
                      </>

                    ))}
                </Alert>
              )}
              <div className='card mt-5'
                   style={{height: 'calc(100vh - 250px)'}}
              >
                  <div ref={scrollableContainerRef}
                       className="card-body d-flex flex-row-fluid"
                       style={{overflowY: 'scroll'}}
                  >
                      <div style={{width:'100%'}}>
                          <SwipeableViews
                            disabled={true}
                            index={activeStep}
                          >
                              <SpaceSelection />
                              <Scoring />
                          </SwipeableViews>
                      </div>
                  </div>
              </div>
              <DialogActions sx={{ position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fafafa',
                  zIndex: 2
              }}>
                  <Button disabled={isPrevDisabled}
                          onClick={handlePrevClicked}>Prev</Button>
                  <Button disabled={isNextDisabled}
                          onClick={handleNextClicked}>Next</Button>
                  {!isEditing && (
                    <Button variant="contained" color="primary" onClick={() => handleSubmitClicked(false)} disabled={isSubmitDisabled}>
                        + Space
                    </Button>
                  )}
                  {isEditing && (
                    <Button variant="contained" color="primary" onClick={() => handleSubmitClicked(false)} disabled={isSubmitDisabled}>
                        Save
                    </Button>
                  )}
              </DialogActions>
          </Dialog>
      </>

    )

}

export default EditInspectedEntity

