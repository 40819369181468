import React, { useState, useEffect } from 'react';
import { TextField, Button, Card, CardContent, Typography } from "@mui/material";
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { Service } from "../../../../../../../../../shared/models/service.model";
import useServiceStore from "../../../../../../../serviceStore";
import ServiceSelectionForm
  from "../../../../../../../../../shared/components/forms/widgets/selection-forms/ServiceSelectionForm";
import { ValidationDialog } from "../../../../../../../../../shared/components/page/popup-dialog/ValidationDialog";
import ParentSelection from "../../../../../../edit-service/ParentSelection";

const ServiceDetails = ({ classes = '', onSave}) => {
  const { selectedService, setSelectedService, services } = useServiceStore();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parent, setParent] = useState<Service | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (selectedService) {
      setName(selectedService.name || '');
      setDescription(selectedService.description || '');
      setParent(selectedService.parent || undefined);
    }
  }, [selectedService]);


  const handleSave = () => {
    if (!name.trim() || !description.trim()) {
      setShowErrorDialog(true);
      return;
    }

    setSelectedService(prevService => {
      if (!prevService) {
        return new Service();
      }

      const updatedService: Service = {
        ...prevService,
        name: name,
        description: description,
        parent: parent,
        getPath: function(): string {
          throw new Error("Function not implemented.");
        }
      };
        return updatedService;

    });

  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveParent = (selectedParentService) => {
    if (selectedService && selectedParentService.id === selectedService.id) {
      alert("A service cannot select itself as its parent.");
      setParent(undefined);
    } else {
      setParent(selectedParentService);
      console.log(selectedParentService);
      setIsDialogOpen(false);
    }
  };


  return (
    <div className={classes}>
      <div className="d-flex flex-row align-items-center justify-content-between" style={{ paddingLeft: 0 }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-800">Details</span>
        </h3>
      </div>
      <div>
        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} sx={{marginBottom: '10px'}} fullWidth />
        <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} sx={{marginBottom: '10px'}} fullWidth multiline />


        {/* Parent Service Field */}
        <div>
          <Card variant="outlined" style={{ marginBottom: '10px' }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                <EscalatorWarningIcon /> Parent Service
              </Typography>
              <Typography variant="body1">{parent?.name || ''}</Typography>
              <Button onClick={handleOpenDialog}>Select Parent Service</Button>
            </CardContent>
          </Card>

          <ParentSelection
            open={isDialogOpen}
            onClose={handleCloseDialog}
            onSelect={handleSaveParent}
            itemToEdit={null}
            data={services} />


        </div>
      </div>
      <ValidationDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} title="Missing Information"
                        description="All services require a name and description." />

      <div className="button-container"
           style={{
             position: 'relative',
             bottom: 0,
             left: 0,
             right: 0,
             zIndex: 1000,
             width: '100%'
           }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ width: "100%", height: '50px', borderRadius: 0, borderTop: '1px solid #e7e7e7' }}
        >
          {'SAVE'}
        </Button>
      </div>
    </div>
  );
};

export default ServiceDetails;

