import React, { useEffect, useState } from "react";
import CsatTile from "./csat-summary/CsatTile";
import JointInspectionTile from "./joint-inspection-summary/JointInspectionTile";
import useAppStore from "../../../../appStore";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import { SurveySummary } from "../../../../shared/models/surveySummary.model";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { JointInspectionSummaryModel } from "../../../../shared/models/jointInspectionSummary.model";

const ClientSummary = () => {
  const { loggedInUser, selectedLocation, whiteLabelProps } = useAppStore();
  const service = new AnalyticsService();
  const [summary, setSummary] = useState<SurveySummary>(new SurveySummary());
  const [stats, setStats] = useState<JointInspectionSummaryModel>(new JointInspectionSummaryModel());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  useEffect(() => {
    const fetchSummaries = async () => {
      setIsLoading(true);
      setError('');
      try {
        const overview = await service.getSurveySummary(
          loggedInUser.organization,
          'CSat',
          selectedLocation
        );
        const jointSummary = await service.getJointSummary(
          loggedInUser.organization,
          selectedLocation,
          fromDate,
          toDate
        );
        setSummary(overview);
        setStats(jointSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummaries().then();
  }, [loggedInUser, selectedLocation.id, fromDate, toDate]);


  if (isLoading) {
    return (
      <CustomLoadingDots />
    );
  }

  if (error) {
    return (
      <CustomError error={error} />
    );
  }

  return (
    <>
      <JointInspectionTile stats={stats} setFromDate={setFromDate} setToDate={setToDate} />
      <CsatTile summary={summary}/>
    </>
);

}



export default ClientSummary;