import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import { IconButton, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import TellSageModal from "../../../../app/modules/cases/components/tell-sage/TellSageModal";
import useCasesStore from "../../../../app/modules/cases/caseStore";
import SageAssistantModal from "../../../../app/modules/sage-virtual-assistant/SageAssistantModal";

const SageAssistantMenu = () => {
  const {reset} = useCasesStore();
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const location = useLocation()

  const hiddenRoutes = ["/facilities/department-cases"];

  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }

  const handleFab = () => {
    reset();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <IconButton
        aria-label="menu"
        onClick={() => handleFab()}
        sx={{
          position: 'fixed',
          bottom: !isMobile ? 16 : isExpanded ? 16 : 88,
          right: 16,
          zIndex: isExpanded ? 1000 : 995,
          width: !isMobile ? 80 : 62,
          height: !isMobile ? 78 : 61,
          padding: 0,
          boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.33)',
          borderRadius: '50%',
          border: isExpanded ? '2px solid #077c5e' : '',
          backgroundColor: '#18aa79',
          '&:hover': {
            backgroundColor: '#18aa79',
          }
        }}
      >
        {isExpanded ?
          <Close sx={{ color: 'white' }} />  :
          <img src={"/white-labeling/verde/media/menu-icons/virtual-assistant.png"} alt="custom-icon"
               style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
        }
      </IconButton>

      <Slide direction="up" in={isExpanded} mountOnEnter unmountOnExit timeout={{ enter: 300, exit: 300 }}>
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, top: 0, zIndex: 998 }}>
          {isExpanded && <TellSageModal onClose={() => setIsExpanded(false)} />}
        </div>
      </Slide>
    </>
  );
};

export default SageAssistantMenu;