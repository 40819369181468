import React, { useEffect, useState } from "react";
import { AppBar, Dialog, IconButton, Typography, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import useAppStore from "../../../../../../appStore";
import { AnalyticsService } from "../../../../../../shared/services/analytics/analyticsService";
import { SurveySummary } from "../../../../../../shared/models/surveySummary.model";
import { ReportingGroupScore } from "../../../../../../shared/models/reportingGroupScore.model";
import SurveySummaryTiles from "../../../../../analytics/components/surveys/components/SurveySummaryTiles";
import CustomerSurveyTierTable from "../../../../../analytics/components/surveys/customer/components/CustomerSurveyTierTable";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";


const CsatModal = ({ open, onActionClicked }) => {

  const { loggedInUser, selectedLocation} = useAppStore();
  const service = new AnalyticsService();
  const [summary, setSummary] = React.useState(new SurveySummary());
  const [timeframe, setTimeframe] = useState('30 days')
  const [reportingGroups, setReportingGroups] = React.useState<ReportingGroupScore[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');
      try {
        const tileSummary = await service.getSurveySummary(
          loggedInUser.organization,
          'CSat'
        );
        const tierData = await service.getReportingGroupScore(loggedInUser.organization, selectedLocation.id, 'CSat')
        setReportingGroups(tierData)
        setSummary(tileSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().then();
  }, [loggedInUser, selectedLocation.id, timeframe]);


  if (isLoading) {
    return (
      <CustomLoadingDots />
    );
  }

  if (error) {
    return (
      <CustomError error={error} />
    );
  }

  return (
    <Dialog fullScreen open={open} onClose={() => onActionClicked('close')} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden', backgroundColor: '#e8e8e8' } }}>
      <AppBar sx={{ position: 'relative', mb: 2 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onActionClicked('close')} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            CSAT summary
          </Typography>
        </Toolbar>
      </AppBar>

      <div style={{padding: '25px'}}>
        <SurveySummaryTiles summary={summary} />
        <CustomerSurveyTierTable data={reportingGroups} />
      </div>


    </Dialog>
  );
};

export default CsatModal;