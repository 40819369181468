import React, { useEffect, useState } from "react";
import { Divider, Tab, Tabs } from "@mui/material";
import LocationContracts from "./components/contracts/LocationContracts";
import LocationServiceLog from "./components/service-log/LocationServiceLog";
import useAppStore from "../../appStore";
import { LocationModel } from "../../shared/models/location.model";
import { LocationService } from "../../shared/services/locations/locationService";
import { useTranslation } from "react-i18next";

const LocationSummaryPage: React.FC = () => {
  const [tab, setTab] = useState(1)
  const {t} = useTranslation();
  const { selectedLocation } = useAppStore();
  const [locationDetail, setLocationDetail] = useState<LocationModel>(selectedLocation);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const service = new LocationService();

    const fetchLocationDetails = async () => {
      setIsLoading(true);
      try {
        const location = await service.getLocationInfo(selectedLocation?.id);
        setLocationDetail(location);
      } catch (error) {
        console.error('Failed to fetch location media:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation?.id) {
      fetchLocationDetails().then();
    }
  }, [selectedLocation?.id]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label={t('scope-of-work')} value={1} />
          <Tab label={t('service-logs')} value={2} />
      </Tabs>
      <Divider sx={{ bgcolor: "#999", marginBottom: "15px" }} />

      {tab === 1 &&
        <LocationContracts readOnly={true}/>
      }
      {tab === 2 && locationDetail.is_qr_scanning_enabled === true &&

            <LocationServiceLog />
      }
      {tab === 2 && locationDetail.is_qr_scanning_enabled === false &&

        <label><i>Service Logs are not turned on for this Location.</i></label>
      }

    </>
  );
};

export default LocationSummaryPage;
