import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const SortOptions = ({ sortBy, setSortBy, sortItems, workItems, setWorkItems }) => {

  const {t} = useTranslation();

  const handleClick = (sortCriterion) => {
    setSortBy(sortCriterion);
    const sortedItems = sortItems(workItems, sortCriterion);
    setWorkItems(sortedItems);
  };

  return (
    <Box sx={{ marginTop: '50px', display: 'flex', alignItems: 'center' }}>
      <span style={{marginLeft: '5px'}}>{t('view-by')}: </span>
      <Button sx={{ marginLeft: '8px', marginRight: '4px', color: (sortBy === 'urgency') ? '#888888' : '' }} size="small" onClick={() => handleClick('create_date')}>{t('most-recent')}</Button>
      <Button sx={{color: (sortBy === 'urgency') ? '' : '#888888' }} size="small" onClick={() => handleClick('urgency')}>{t('urgency')}</Button>
    </Box>
  );
};

export default SortOptions;