import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material';
import useAppStore from "../../../../appStore";

const EmployeeDetailRow = ({ employee }) => {
  const {appImages} = useAppStore();
  const fullName = `${employee.person.first_name} ${employee.person.last_name}`;
  const locationName = Array.isArray(employee?.works_at) && employee.works_at.length > 0
    ? employee.works_at[0]?.location?.name
    : "No location";

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={fullName} src={appImages.iconBlankProfilePic} />
      </ListItemAvatar>
      <ListItemText
        primary={fullName}
        secondary={
          <Typography variant="body2" color="textSecondary">
            {locationName}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default EmployeeDetailRow;
