import React, { useState } from "react";
import {
  AppBar, Button, Dialog, Grid, IconButton, TextField, Toolbar, Typography
} from "@mui/material";
import { CloseIcon } from "yet-another-react-lightbox";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import useSpaceStore from "../../spaceStore";
import { Entity } from "../../../../../../shared/models/entity.model";
import { EntityType } from "../../../../../../shared/models/entityType.model";
import useAppStore from "../../../../../../appStore";
import TypeAndSubTypes from "./components/TypeAndSubTypes";
import ParentSelect from "./components/ParentSelect";
import ServicingPanel from "./components/ServicingPanel";
import LogPanel from "./components/LogPanel";
import { ValidationDialog } from "../../../../../../shared/components/page/popup-dialog/ValidationDialog";


const EditSpace = ({ spaceToEdit, setSpaceToEdit, open, onClose, onSave }) => {

  const {selectedSpace} = useSpaceStore();
  const [name, setName] = useState(spaceToEdit.name);
  const [shortName, setShortName] = useState(spaceToEdit.short_name);
  const [description, setDescription] = useState(spaceToEdit.description);
  const [parent, setParent] = useState<Entity | null>(spaceToEdit.id ? spaceToEdit.parent : selectedSpace);
  const [spaceType, setSpaceType] = useState<EntityType | null>(spaceToEdit.entity_type);
  const [subTypes, setSubTypes] = useState<EntityType[]>(spaceToEdit.entity_types_to_service);
  const [inService, setInService] = useState(spaceToEdit.in_service);
  const [frequency, setFrequency] = useState(spaceToEdit.inspection_frequency);
  const [jointFrequency, setJointFrequency]  = useState(spaceToEdit.joint_inspection_frequency);
  const [hasLog, setHasLog] = React.useState(spaceToEdit.has_service_log);
  const [logFrequency, setLogFrequency] = React.useState(spaceToEdit.service_log_scan_frequency);

  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const {loggedInUser, selectedLocation} = useAppStore()


  const handleSave = () => {
    const updatedSpace = {
      ...spaceToEdit,
      name: name,
      short_name: shortName,
      description: description,
      parent: parent,
      location: selectedLocation,
      organization: loggedInUser?.organization,
      entity_type: spaceType,
      in_service: inService,
      inspection_frequency: frequency,
      kind_of: 'Place',
      entity_types_to_service: subTypes,
      joint_inspection_frequency: jointFrequency,
      has_service_log: hasLog,
      service_log_scan_frequency: logFrequency,
    };

    if (!name.trim()) {
      setShowErrorDialog(true);
      return;
    }

    onSave(updatedSpace);
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <div>
        <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Space
            </Typography>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleSave}
              sx={{ ml: 'auto', color: '#444444' }}
            >
              {spaceToEdit.id === 0 ? 'Submit' : 'Save'}
            </Button>
          </Toolbar>
        </AppBar>

        <div style={{ padding: "20px", paddingLeft: "50px", paddingRight: "50px", marginTop: '60px' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: '1.1rem', color: '#3a3b3c' }}>Space
            Type</Typography>
          <TypeAndSubTypes spaceType={spaceType} setSpaceType={setSpaceType} subTypes={subTypes}
                           setSubTypes={setSubTypes} />

          <Typography variant="subtitle1"
                      sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Name</Typography>
          <TextField value={name} onChange={(e) => setName(e.target.value)} fullWidth sx={{ marginBottom: "20px" }} />

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Short
            Name</Typography>
          <TextField value={shortName} onChange={(e) => setShortName(e.target.value)} fullWidth
                     sx={{ marginBottom: "20px" }} />

          <Typography variant="subtitle1"
                      sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}>Parent</Typography>
          <ParentSelect parent={parent} setParent={setParent} />

          <Typography variant="subtitle1" sx={{
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: '#3a3b3c',
            marginTop: '20px'
          }}>Description</Typography>
          <TextField value={description} onChange={(e) => setDescription(e.target.value)} fullWidth multiline rows={3}
                     sx={{ marginBottom: "20px" }} />

          <div className="d-flex flex-row">
            <div className='w-50 pe-5'>
              <ServicingPanel inService={inService} setInService={setInService} frequency={frequency}
                              setFrequency={setFrequency} jointFreq={jointFrequency} setJointFreq={setJointFrequency} />
            </div>
            <div className='w-50 ps-5'>
              <LogPanel hasLog={hasLog} setHasLog={setHasLog} scanFreq={logFrequency} setScanFreq={setLogFrequency} />
            </div>
          </div>


        </div>

        <ValidationDialog open={showErrorDialog} onClose={() => setShowErrorDialog(false)} title="Missing Information"
                          description="All spaces require a name" />


      </div>
    </Dialog>
  );
}

export default EditSpace;