import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SelectDropdownField = ({ label, value, onChange, options }) => {
  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <span className="text-gray-700">Nature of Incident</span>
      <Select value={value} onChange={onChange} label={label}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropdownField;
