import { Employee } from "./employee.model";

export class Message {

  sender: Employee;
  message: string;
  location: number;
  provider: number;

  constructor() {
    this.sender = new Employee();
    this.message = '';
    this.location = 0;
    this.provider = 0
  }

}