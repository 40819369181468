import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  AppBar,
  IconButton,
  Typography,
  Box,
  Toolbar,
  Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CaseReadOnly from "../../../../../cases/components/edit-case/components/details/read-only/CaseReadOnly";
import WorkItemReadOnly from "../../../../../work-items/components/edit-work-item/components/details/WorkItemReadOnly";

const ItemDetails = ({ open, onClose, item, type }) => {

  return (
    <>
      {open &&
        <Box sx={{ mt: 1 }}>
          {type === 'case' &&
            <CaseReadOnly caseToEdit={item} headers={false}/>
          }
          {type === 'workitem' &&
            <WorkItemReadOnly workItemToEdit={item} headers={false}/>
          }
        </Box>
      }
    </>

  );
};

export default ItemDetails;
