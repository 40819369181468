// SagePrompter.tsx
import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";

interface SageBotResponseProps {
  sageResponse: string;
  typing?: boolean;
  onTypingComplete?: () => void;
  color?: string;
}

const bubbleStyle = {
  margin: 2,
  padding: 2,
  marginTop: 4,
  maxWidth: '650px',
  paddingTop: 5,
  borderRadius: '15px',
  position: 'relative' as 'relative',
  '&:after': {
    content: '""',
    position: 'absolute' as 'absolute',
    bottom: '100%',
    left: '50%',
  },
};

const avatarStyle = {
  position: 'absolute' as 'absolute',
  top: -10,
  left: 5,
  width: 50,
  height: 43,
};


const SageBotReponse: React.FC<SageBotResponseProps> = ({
                                                          sageResponse,
                                                          typing = false,
                                                          onTypingComplete,
                                                          color = 'black',
                                                        }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (typing) {
      setDisplayedText("");
      let index = 0;
      interval = setInterval(() => {
        setDisplayedText(sageResponse.slice(0, index + 1));
        index++;
        if (index >= sageResponse.length) {
          clearInterval(interval);
          if (onTypingComplete) {
            onTypingComplete();
          }
        }
      }, 16);
    } else {
      setDisplayedText(sageResponse);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [sageResponse, typing, onTypingComplete]);

  return (
    <Box sx={{ padding: 1, paddingTop: 0.5}}>
      <Paper elevation={3} sx={bubbleStyle}>
        <Box
          sx={{
            ...avatarStyle,
            borderRadius: '50%',
            overflow: 'hidden',
          }}
        >
          <img
            src="/white-labeling/verde/media/menu-icons/virtual-assistant.png"
            alt="Sage"
            style={{
              marginLeft: '3px',
              height: '100%',
              width: '85%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>
        <Box>
          <Typography sx={{ color: color }} variant="h6" gutterBottom>
            {displayedText}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default SageBotReponse;
