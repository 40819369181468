import { BaseSerializer } from "./baseSerializer";
import { Message } from "../message.model";

export class MessageSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let message = new Message();

    this._copyAttributes(message, data);

    return message;

  }

}