import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { WorkItem } from "../../../../../../../../shared/models/workItem.model";
import { InspectedEntity } from "../../../../../../../../shared/models/inspectedEntity.model";
import { useWorkItem } from "../../../../../../../work-items/context/workItemContext";
import useAppStore from "../../../../../../../../appStore";
import { DateTime } from 'luxon';
import CustomError from "../../../../../../../../shared/components/page/popup-dialog/CustomError";
import WhereAndWhat
  from "../../../../../../../work-items/components/edit-work-item/components/where-and-what/WhereAndWhat";
import WorkItemForm from "../../../../../../../work-items/components/edit-work-item/components/details/WorkItemForm";
import AssignedTo from "../../../../../../../work-items/components/edit-work-item/components/assigned-to/AssignedTo";
import { WorkItemPrefillModel } from "../../../../../../../../shared/models/workItemPrefill.model";
import { WorkItemsService } from "../../../../../../../../shared/services/work-items/workItemsService";
import { Button } from "@mui/material";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

interface InspectionWorkItemProps {
  inspectedEntity?: InspectedEntity;
  onActionClicked: (action: string) => void;
}

const InspectionWorkItem: React.FC<InspectionWorkItemProps> = ({ inspectedEntity = null, onActionClicked }) => {
  const [prefill, setPrefill] = useState<WorkItem>(new WorkItem());
  const { workItemToEdit, setWorkItemToEdit, setSortBy } = useWorkItem();
  const { loggedInUser, selectedLocation, toggleMasterRefresh } = useAppStore();
  const [newComments, setNewComments] = useState([]);
  const [error, setError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(workItemToEdit.id === 0);
  const [generatedPrefill, setGeneratedPrefill] = useState<WorkItemPrefillModel>(new WorkItemPrefillModel());
  const [isLoading, setIsLoading] = useState(true); // Default to true until prefill is fetched

  const initializeWorkItem = () => {
    setWorkItemToEdit(prevWorkItem => ({
      ...prevWorkItem,
      due_date: DateTime.now().toUTC().plus({ days: 1 }).toISO(),
      created_by: loggedInUser.employee,
      location: {
        ...prevWorkItem.location,
        id: selectedLocation?.id || loggedInUser?.employee?.works_at?.[0]?.location?.id || 0,
      },
      provider: {
        ...prevWorkItem.provider,
        id: loggedInUser.organization?.id || 0,
      },
    }));
  };

  const getWorkItemPrefill = async () => {
    const entityId = inspectedEntity?.id;
    if (typeof entityId !== 'number' || entityId <= 0) {
      console.log("Invalid or missing entity ID:", entityId);
      return;
    }

    setIsLoading(true);
    try {
      const service = new WorkItemsService();
      const prefilledWorkItem = await service.getPrefill(entityId);
      setPrefill(prefilledWorkItem);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (prefill) {
      setWorkItemToEdit(prevWorkItem => ({
        ...prevWorkItem,
        title: prefill?.title || prevWorkItem.title,
        description: prefill?.description || prevWorkItem.description,
        where_and_what: prefill?.where_and_what || prevWorkItem.where_and_what,
        media: prefill?.media || prevWorkItem.media,
        source: inspectedEntity ? { type: 'Inspection', inspected_entity: inspectedEntity } : null,
      }));
    }
  }, [prefill]);

  useEffect(() => {
    if (inspectedEntity && inspectedEntity.id && inspectedEntity.id !== -1) {
      getWorkItemPrefill().then();
    }
  }, [inspectedEntity]);

  useEffect(() => {
    if (!workItemToEdit.id) {
      initializeWorkItem();
    } else {
      const fetchWorkItemDetails = async () => {
        try {
          const service = new WorkItemsService();
          const workItem = await service.getWorkItemDetails(workItemToEdit.id);
          setWorkItemToEdit(workItem);
        } catch (error) {
          console.error('Failed to fetch work item details:', error);
        }
      };
      fetchWorkItemDetails();
    }
  }, [workItemToEdit.id]);

  const handleSubmit = async () => {
    try {
      const service = new WorkItemsService();
      const isCreating = workItemToEdit.id === 0;
      const response = isCreating
        ? await service.createWorkItem(workItemToEdit)
        : await service.updateWorkItem(workItemToEdit);

      if (isCreating) setSortBy("create_date");
      const statusToStepMap = { 'Pending': '0', 'Closed': '2' };
      localStorage.setItem("activeStep", statusToStepMap[response.status] || '1');
      setNewComments([]);
      onActionClicked('save');
      toggleMasterRefresh();
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    }
  };

  const handleCancel = async () => {
    setWorkItemToEdit(new WorkItem());
    onActionClicked('cancel');
  };

  const createPrefill = async (workItem) => {
    try {
      let source = new WorkItemPrefillModel();
      source.work_item = workItem;
      const response = await new WorkItemsService().createWorkItemPrefill(source);
      setGeneratedPrefill(response);
    } catch (e) {
      console.error('Error in prefill generation:', e);
    }
  };

  const renderCard = (Component, props = {}) => (
    <Card style={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)', margin: '5px', marginBottom: '10px', borderRadius: '0px' }}>
      <Card.Body>
        <Component {...props} />
      </Card.Body>
    </Card>
  );

  if (isLoading) return <CustomLoadingDots />;
  if (error) { return <CustomError error={error} /> };
  if (!workItemToEdit || !prefill) return null; // Ensure both are set before rendering

  return (
    <div style={{ zIndex: 1000 }}>
      {renderCard(WhereAndWhat, { fromInspection: inspectedEntity, isEditing, createPrefill })}
      {renderCard(WorkItemForm, { workItemToEdit, setWorkItemToEdit, isEditing })}
      {renderCard(AssignedTo, { workItemToEdit, setWorkItemToEdit, isEditing })}

      <div className="d-flex flex-column justify-content-between me-5">
        <Button color="inherit" variant="contained" onClick={handleSubmit} sx={{ color: "#444444", fontSize: '1.3rem', width: '100%', margin: 1 }}>
          Submit
        </Button>
        <Button color="inherit" variant="contained" onClick={handleCancel} sx={{ color: "#444444", fontSize: '1.3rem', width: '100%', margin: 1 }}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InspectionWorkItem;
