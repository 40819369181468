import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../../appStore";
import EditLocationContact from "./components/EditLocationContact";
import { ContactService } from "../../../../../../shared/services/location-contacts/contactService";
import useInspectionsStore from "../../../../inspectionsStore";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { Card } from "@mui/material";
import { Employee } from "../../../../../../shared/models/employee.model";
import { Contact } from "../../../../../../shared/models/contact.model";
import { useTranslation } from "react-i18next";

const JointInspectedByCard = () => {

  const {
      inspection,
      setInspection
  } = useInspectionsStore();

  const { t } = useTranslation();
  const { whiteLabelProps, selectedLocation } = useAppStore();
  const [isAddContactOpen, setIsAddContactOpen] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contact, setContact] = useState<Employee | null>(inspection.joint_inspected_by);
  const [contactToEdit, setContactToEdit] = useState<Contact>(new Contact());
  const [isJoint, setIsJoint] = useState<boolean>(inspection.is_joint_inspection);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contactService = new ContactService();

  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      const contactsData = await contactService.getContacts(selectedLocation.id);
      setContacts(contactsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsJoint(inspection.is_joint_inspection)
    setContact(inspection.joint_inspected_by)
  }, [inspection]);

  useEffect(() => {
    fetchContacts();
  }, [refresh]);

  if (isLoading) {
    return <CustomLoadingDots />;
  }

  const handleRadioChange = (event) => {
    const value = event.target.value === 'yes';
    setIsJoint(value)
  };

  const handleContactSelected = (event) => {
    const selectedContactId = event.target.value;
    const selectedContact = contacts.find(c => c.employee.id === parseInt(selectedContactId));

    if (selectedContact) {
      setContact(selectedContact.employee);
      inspection.joint_inspected_by = selectedContact.employee
      inspection.is_joint_inspection = true
      setInspection(inspection)
    } else {
      setContact(null);
      inspection.joint_inspected_by = null;
      inspection.is_joint_inspection = false;
      setInspection(inspection)
    }

  };

  const handleAddContactOpen = () => {
    setContactToEdit(new Contact())
    setIsAddContactOpen(true);
  };

  const handleAddContactClose = () => {
    setIsAddContactOpen(false);
    setRefresh(!refresh)
  };

    return (
        <Card variant="outlined" sx={{
        marginTop: "15px",
        boxShadow: "0 1px 2px rgba(0,0,0,0.1)"
      }}>
        <div className="card-header p-8">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">{t('joint-inspection')}</span>
          </h3>
        </div>
        <div className="card-body p-7 pt-4">
          <div className="mb-10">
            <div className="d-flex align-items-center">
              <div className="form-check form-check-custom form-check-solid me-10">
                <input
                  className="form-check-input"
                  style={{ backgroundColor: isJoint ? whiteLabelProps.primary_color : undefined }}
                  type="radio"
                  id="yes"
                  name="joint-inspection"
                  value="yes"
                  checked={isJoint}
                  onChange={handleRadioChange} />
                <label className="form-check-label" htmlFor="yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  style={{ backgroundColor: !isJoint ? whiteLabelProps.primary_color : undefined }}
                  type="radio"
                  id="no"
                  name="joint-inspection"
                  value="no"
                  checked={!isJoint}
                  onChange={handleRadioChange} />
                <label className="form-check-label" htmlFor="no">
                  No
                </label>
              </div>
            </div>
          </div>
          {isJoint && (
            <div className="mb-10">
              <label className="form-label fw-bold">Contact</label>
              <div className="input-group">
                <select
                  className="form-select form-select-solid"
                  value={contact ? contact.id : ""}
                  onChange={handleContactSelected}
                >
                  <option value="">Select contact...</option>
                  {contacts.map((c) => (
                    <option key={c.employee.id} value={c.employee.id}>
                      {`${c.employee.person.first_name} ${c.employee.person.last_name}`}
                    </option>
                  ))}
                </select>
                <button
                  className="btn"
                  style={{
                    backgroundColor: whiteLabelProps.primary_color,
                    color: "white",
                    fontSize: "12px"
                  }}
                  onClick={handleAddContactOpen}
                >
                  ADD CONTACT
                </button>
              </div>
            </div>
          )}
        </div>
        <EditLocationContact contactToEdit={contactToEdit} open={isAddContactOpen} onClose={handleAddContactClose}
                             onSave={handleAddContactClose} />
      </Card>
    );
};

export default JointInspectedByCard;
