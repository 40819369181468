import { CourseSerializer } from "../../../models/serializers/courseSerializer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";


export class CommandGetCourses {

    TRAINING_SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

    getUrl() {

        return  `${this.TRAINING_SERVICE}/training/courses/`;
    }

    async run(org_id, category_id, offset=0, limit=10, search=null) {

        const url = this.getUrl()

        const params = {
            params: {
                organization: org_id,
                category: category_id,
                offset: offset,
                limit: limit,
                search: search
            }
        }

        try {
            const response = await httpClient.get(url, params);
            return this.deserialize(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    deserialize(data) {

        const serializer = new CourseSerializer()
        const services = serializer.deserializeList(data)

        return services
    }

}