import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { QuestionSerializer } from "../../../models/serializers/questionSerializer";

export class CommandGetSurveyQuestionAverages {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/analytics/`;
  }


  async run(organization, location, reportingGroupEntryId, surveyType) {
    const url = this.getUrl();

    const params = {
      params: {
        organization: organization.id,
        location: location,
        module: 'surveys',
        charts: 'avg_scores_by_question',
        survey_type: surveyType,
        reporting_group_entry: reportingGroupEntryId
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data.avg_scores_by_question);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new QuestionSerializer()
    const questions = serializer.deserializeList(data)
    return questions
  }

}