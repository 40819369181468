import React from 'react';
import useAppStore from "../../../../../appStore";

const CommentsRow = (props) => {
  const { row } = props;
  const { appImages } = useAppStore();

  if (!row['comments'] || row['comments'].trim().length === 0) {
    return null;
  }

  return (
    <tr>
      <td width="100%">
        <div className="comments-row-container">
          <div className="speech-bubble">
            <i style={{ color: 'grey' }}>"{row['comments']}"</i>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CommentsRow;
