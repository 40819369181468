import React from "react";
import {
  Dialog,
  DialogContent,
  AppBar, IconButton,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import SearchableTreeView from "../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";


const ParentSelection = ({ itemToEdit, open, onSelect, onClose, data }) => {

  const onTreeItemSelected = (item) => {
    onSelect(item);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ width: '80%', maxWidth: '800px', mx: 'auto' }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <SearchableTreeView
          data={data}
          onItemSelected={onTreeItemSelected}
          itemToEdit={itemToEdit}
          showNone={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ParentSelection;
