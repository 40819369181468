import React from 'react';
import useTrainingStore from "../../../trainingStore";
import TrainingCatalogTree from "./TrainingCatalogTree";
import CustomError from "../../../../../../shared/components/page/popup-dialog/CustomError";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const TrainingHierarchy = ({ onCategorySelected }) => {
    const { trainingCategories, setSelectedCategory, categoriesLoading, error } = useTrainingStore();

    const onCategorySelectedWrapper = async (selected) => {
        setSelectedCategory(selected);
        onCategorySelected(selected);
    };

    if (categoriesLoading) return <CustomLoadingDots />;
    if (error) return <CustomError error={error} />;

    return (
      <TrainingCatalogTree data={trainingCategories} onItemSelected={onCategorySelectedWrapper} />
    );
};

export default TrainingHierarchy;

