import { BaseSerializer } from "./baseSerializer";
import { CaseType } from "../caseType.model";

export class CaseTypeSerializer extends BaseSerializer {


  public deserialize( data ): any {

    const entry = new CaseType();

    this._copyAttributes(entry, data);

    return entry;

  }

}