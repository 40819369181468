import { Organization } from "./organization.model";

export class TrainingCategory {

    id: number;
    organization: Organization;
    name: string;
    description: string;
    children: TrainingCategory[];
    parent: TrainingCategory | undefined;

    constructor() {
        this.id = 0;
        this.organization = new Organization();
        this.name = '';
        this.description = '';
        this.children = [];
        this.parent = undefined;
    }

}

