import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider, Button } from "@mui/material";
import useServiceStore from "../../../../serviceStore";
import useAppStore from "../../../../../../appStore";
import InspectionPoints from "./service-sections/components/inspection-points/InspectionPoints";
import SuppliesCard from "./service-sections/components/supplies/SuppliesCard";
import Description from "./service-sections/components/description/Description";

const ServiceItemEdit = ({ isEditing, setIsEditing }) => {
    const { selectedServiceItem, selectedService } = useServiceStore();
    const [selectedTab, setSelectedTab] = useState(0);
    const {appImages} = useAppStore();

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
      <>
          <Box
            sx={{
                marginTop: '20px',
                minHeight: '550px',
            }}
          >
              {selectedServiceItem ? (
                <>
                  <div className="d-flex flex-row justify-content-between">
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                      {selectedService?.service_methods?.map((method, index) => (
                        <Tab label={method.name} key={method.id} />
                      ))}
                    </Tabs>
                    <Button variant="contained" onClick={() => setIsEditing(!isEditing)} style={{height: '40px', marginRight: '5px'}} disabled={!selectedServiceItem}>
                      {isEditing ? 'Save' : 'Edit'}
                    </Button>
                  </div>

                    <Divider sx={{backgroundColor: '#999'}}/>
                    <div>
                      <InspectionPoints classes="mt-7 ps-10 mb-15" isEditing={isEditing} />
                      <Box sx={{ borderBottom: '1px dashed #dcdcdc', marginY: '10px' }} />
                      <SuppliesCard classes="mt-7 ps-10 mb-15" isEditing={isEditing} />
                      <Box sx={{ borderBottom: '1px dashed #dcdcdc', marginY: '10px' }} />
                      <Description classes="mb-25 ps-10" isEditing={isEditing}/>
                    </div>
                </>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" textAlign="center" p={2} mt={13}>
                <div>
                    <img src={appImages.iconMenuDropdown}
                         style={{ width: '175px', height: '175px', marginBottom: '10px' }} />
                    <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                      No Service Item Selected.
                    </p>
                    <p>Select a service item from the dropdown to view/edit.</p>
                  </div>
                </Box>
              )}
          </Box>
      </>
    );
};

export default ServiceItemEdit;

