import useAppStore from "../../../../../../../../../appStore";
import React from "react";

const FractionalScoreSelector = ({ selectedFraction, setSelectedFraction }) => {
  const fractions = [0.25, 0.5, 0.75];
  const { whiteLabelProps } = useAppStore();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {fractions.map((fraction) => (
        <button
          key={fraction}
          onClick={() => setSelectedFraction(fraction)}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            background: selectedFraction === fraction ? whiteLabelProps.primary_color : '#fff',
            color: selectedFraction === fraction ? 'white' : '',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: '28%',
            maxWidth: '80px'
          }}
        >
          {fraction}
        </button>
      ))}
    </div>
  );
};

export default FractionalScoreSelector;