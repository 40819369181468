import { MessageSerializer } from "../../../models/serializers/messageSerializer";
import useTracer from "../../../framework/tracer/tracer";
import httpClient from "../../../utils/http-clients/djangoHttpClient";

export class CommandGetSageResponse {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return  `${this.SERVICE}/chat/`;
  }

  async run(message) {
    const url = this.getUrl();
    const tracer = useTracer.getState();
    tracer.trace('Comments', 'Api', `POST ${url}`);

    try {
      const data = MessageSerializer.serialize(message);
      const response = await httpClient.post(url, data);

      if (response.data && typeof response.data.message === 'string') {
        console.log('Received response:', response.data.message);
        return { message: response.data.message };
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Invalid response format');
      }
    } catch (error) {
      tracer.trace('Comments', 'Error', `${error}`);
      console.error('Error when posting comment:', error);
      throw error;
    } finally {
      tracer.trace('Comments', 'Api', 'Success');
    }
  }


}