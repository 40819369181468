import { Box, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import React from "react";
import useAppStore from "../../../appStore";

const SafetyHeader = ({handleNewIncident}) => {
  const {whiteLabelProps} = useAppStore();

  return (
    <Box sx={styles.container}>
      {/* Emergency Contact Numbers */}
      <List>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>Dial 911 for All Life-Threatening Emergencies</Typography>}
            secondary={
              <Typography variant="h6" sx={{ color: whiteLabelProps.primary_color }}>
                911
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>Reach Medcor Nurse Triage for All Non-Life-Threatening Emergencies</Typography>}
            secondary={
              <Typography variant="h6" sx={{ color: whiteLabelProps.primary_color }}>
                1-844-842-2540
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography sx={styles.primaryText}>Reach Verde EHS at</Typography>}
            secondary={
              <>
                <Typography variant="body2" sx={styles.secondaryText}>
                  Via “Hey Sage” - EHS - General EHS Questions
                </Typography>
                <Typography variant="body2" sx={styles.secondaryText}>
                  <Link href="mailto:EHS@verdeclean.com">EHS@verdeclean.com</Link>
                </Typography>
                <Typography variant="body2" sx={styles.secondaryText}>
                  <Link href="tel:1-888-Verde-19">1-888-Verde-19 - Option 1</Link>
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>

      <Divider sx={{ marginY: 2 }} />

      {/* Incident Response Steps */}
      <Typography variant="h6" sx={{ ...styles.sectionHeader, fontSize: '1rem', color: '#333', fontWeight: 'bold' }}>
        Incident Response Steps
      </Typography>
      <Box sx={styles.stepsContainer}>
        <Typography variant="body1" sx={{ ...styles.description, marginBottom: '10px' }}>
          For <strong>ALL Non-Life-Threatening Emergencies</strong>, call the Medcor Nurse Triage line at <strong>1-844-842-2540</strong>.
        </Typography>
        <Typography variant="body1" sx={{...styles.description, marginBottom: '10px'}}>
          <strong></strong> Report <strong>ALL Incidents</strong> to your Regional/Account Director within <strong>1 Hour</strong> of an Incident.
        </Typography>
        <Typography variant="body1" sx={{...styles.description, marginBottom: '10px'}}>
          <strong></strong> Submit the <strong>Incident Intake</strong> Immediately after Reporting the Incident to your Regional/Account Director. Submit by clicking on the <Link href="#incident-intake" onClick={handleNewIncident}>Incident Intake</Link> link below or via “Hey Sage.”
        </Typography>
        <Typography variant="body2" sx={{ ...styles.note, fontSize: '0.875rem' }}>
          * The Incident Intake Form will notify Verde EHS. EHS will contact you within 24 hours of the incident.
        </Typography>
        <Typography variant="body2" sx={{ ...styles.note, fontSize: '0.875rem' }}>
          * Upload any additional documentation to <Link href="mailto:EHS@verdeclean.com">EHS@verdeclean.com</Link>
        </Typography>
      </Box>
    </Box>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: "'Arial', sans-serif",
    color: "#333",
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '30px',
    marginTop: '10px',
  },
  primaryText: {
    fontWeight: '500',
  },
  secondaryText: {
    color: '#666',
  },
  noteHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#555',
  },
  description: {
    lineHeight: '1.6',
  },
  sectionHeader: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#999'
  },
};

export default SafetyHeader;
