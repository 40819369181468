// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react'
import { TreeItem, TreeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import {Chip, InputAdornment, Popover, TextField } from '@mui/material';
import { filterTree } from './utils/filterTree';
import { findTreeNode } from './utils/findTreeNode';
import {findMatchedNodes} from "./utils/findMatchedNodes";


const SearchableTreeView = (props) => {

    const {
        data=[],
        onItemSelected,
        onRenderLabel=null,
        classes='',
        itemToEdit=null,
        text='Search',
        showNone=true
    } = props

    const treeItemClasses: TreeItemClasses = {
        root: '',
        group: '',
        content: '',
        expanded: '',
        selected: '',
        focused: '',
        disabled: '',
        iconContainer: '',
        label: 'fs-5'
    }

    const textFieldRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [treeData, setTreeData] = React.useState(data);
    const [selectedItem, setSelectedItem] = React.useState(itemToEdit);
    const [searchText, setSearchText] = React.useState('');
    const [expandedNodes, setExpandedNodes] = React.useState([]);

    useEffect(() => {
        setTreeData(data);
        if (itemToEdit) {
            setSelectedItem(itemToEdit);
        }
    }, [data, itemToEdit]);


    const handleNodeSelected = (event, nodeId) => {
        let selectedItem = null

        for (let item of treeData) {
            selectedItem = findTreeNode(item, Number(nodeId))

            if (selectedItem !== null) {
                setSelectedItem(selectedItem);
                onItemSelected(selectedItem)
                return
            }
        }

    }

    const handleOpenPopover = (event) => {

        setAnchorEl(event.target);
    }

    const handleBlur = (event) => {
        if (
            event.relatedTarget !== textFieldRef.current &&
            event.relatedTarget !== anchorEl
        ) {
            handleClosePopover();
        }
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setSearchText('');
        setExpandedNodes([]);
        treeData.map((item) => { filterTree(item, '') });
        setTreeData(treeData)

    };

    const handleSearchChanged = (event) => {

        let text = event.target.value

        treeData.map( (item) => {filterTree(item, text)})

        let nodes = new Array()

        treeData.map((item) => { findMatchedNodes(item, nodes) });

        setExpandedNodes(nodes);
        setSearchText(text)
        setTreeData(treeData)

    }

    const handleToggle = (event, nodeIds) => {
        setExpandedNodes(nodeIds);
    };


    const renderLabel = (node) => {

        if (onRenderLabel !== null){
            return onRenderLabel(node.name)
        }

        return (
            <div className='d-flex justify-content-between align-items-center' onClick={(event) => handleLabelClick(event, node.id)}>
                <span>
                    {node.name}
                </span>

            </div>
        )
    };

    const handleLabelClick = (event, nodeId) => {
        event.stopPropagation();
        handleNodeSelected(event, nodeId);
        handleClosePopover();
    };

    /*
       Recursive function that renders the Tree.
       Tree structure is
       {  <-- node
          id: 1,
          name: 'node name',
          children: []  <-- children can have children
       }
     */
    const renderTree = (node, type) => {

        if (node.matched != undefined && !node.matched){
            return null
        }

        return (  <TreeItem key={node.id}
                            nodeId={`${node.id.toString()}`}
                            label={renderLabel(node)}
                            classes={treeItemClasses}>
                            {
                                Array.isArray(node.children)
                                  ? node.children.map((node) => renderTree(node, type))
                                  : null
                            }

                 </TreeItem>
        )
    };

    return (
        <>
            <div className={classNames("", classes)}>
                <TextField
                    id="outlined-controlled"
                    label={text}
                    value={searchText}
                    ref={textFieldRef}
                    onFocus={handleOpenPopover}
                    onChange={handleSearchChanged}
                    fullWidth
                    InputProps={{
                        autoComplete: 'off',
                        startAdornment: (
                          <InputAdornment position="start">
                              {(selectedItem || showNone) &&
                                <Chip
                                  label={selectedItem ? selectedItem.name : 'None'}
                                  onDelete={selectedItem ? () => setSelectedItem(null) : undefined}
                                  sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
                                />
                              }
                          </InputAdornment>
                        ),
                    }}
                />

                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={textFieldRef.current}
                    onClose={handleBlur}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical:   'top',
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <TreeView
                      aria-label="file system navigator"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      onNodeToggle={handleToggle}
                      expanded={expandedNodes}
                      sx={{  width: 400, height: 200 }}>
                        {
                            treeData.map((item) => (
                              renderTree(item, item.name)
                            ))
                        }
                    </TreeView>
                </Popover>
            </div>
        </>
    )

}

export default SearchableTreeView
