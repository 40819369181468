import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import SageBotReponse from "./SageBotResponse";
import useAppStore from "../../../../../appStore";
import { Message } from "../../../../../shared/models/message.model";
import { Employee } from "../../../../../shared/models/employee.model";
import { SageChatService } from "../../../../../shared/services/sage-chat/sageChatService";


const ChatComponent: React.FC = () => {
  const {whiteLabelProps, loggedInUser, selectedLocation} = useAppStore();
  const service = new SageChatService();
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [chatInputValue, setChatInputValue] = useState<string>('');
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false);
  const [currentBotMessage, setCurrentBotMessage] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const handleChatSend = async () => {
    if (chatInputValue.trim() === '') return;

    const userMessage = chatInputValue.trim();

    // Add user's message to chatMessages
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: loggedInUser.employee, message: userMessage, location: selectedLocation.id, provider: loggedInUser?.organization.id },
    ]);

    setChatInputValue('');
    setIsBotTyping(true);

    try {
      console.log('Sending message:', userMessage);
      let message = new Message();
      message.message = userMessage;
      message.location = selectedLocation.id;
      message.provider = loggedInUser?.organization.id;

      const response = await service.getResponse(message);
      const botReply = response.message || 'Error: Could not retrieve message.';

      console.log('Bot reply:', botReply);

      // Add bot's message to chatMessages
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: new Employee(), message: botReply, location: selectedLocation.id, provider: loggedInUser?.organization.id },
      ]);

    } catch (error) {
      console.error('Error sending message to chatbot API:', error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: new Employee(), message: 'Error: Unable to get response from chatbot.', location: selectedLocation.id, provider: loggedInUser?.organization.id },
      ]);
    } finally {
      setIsBotTyping(false);
    }
  };



  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, isBotTyping]);

  const handleTypingComplete = () => {
    console.log('Typing complete. Adding bot message:', currentBotMessage);

    setChatMessages((prevMessages) => [
      ...prevMessages,
      { sender: new Employee(), message: currentBotMessage, location: selectedLocation.id, provider: loggedInUser?.organization.id },
    ]);

    setCurrentBotMessage('');
    setIsBotTyping(false);
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Scrollable chat history */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
        <Typography component="div" className="text-muted" sx={{ flexGrow: 1, fontStyle: 'italic', textAlign: 'center' }}>Sage has joined the chat</Typography>
        {chatMessages.map((message, index) => (
          <div key={index}>
            {message.sender.id !== -1 ? (
              <Box sx={{ textAlign: 'right', mb: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    display: 'inline-block',
                    backgroundColor: whiteLabelProps.primary_color,
                    color: 'white',
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  {message.message}
                </Typography>
              </Box>
            ) : (
              <SageBotReponse
                sageResponse={message.message}
                typing={false} // Typing false because it's already completed
              />
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      {/* Fixed input field at the bottom */}
      <Box sx={{ p: 2, borderTop: '1px solid #ccc' }}>
        <Box sx={{ display: 'flex', marginRight: '80px', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Type your message..."
            value={chatInputValue}
            onChange={(e) => setChatInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleChatSend();
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatComponent;


