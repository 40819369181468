import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

interface TileProps {
  title: string;
  image: string | null;
  subTitle?: string;
  width?: number;
  height?: number;
}

const NavigationTile: React.FC<TileProps> = ({ title, image, subTitle, width = 50, height = 50 }) => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <div style={isMobile ? styles.mobileTile : styles.tile}>
      {image && (
        <img
          src={image}
          style={{ width: width, height: height, marginRight: isMobile ? '' : '10px', marginBottom: isMobile ? '10px' : '' }}
          alt="Icon"
        />
      )}
      <div>
        <Typography variant="h5">{title}</Typography>
        {subTitle &&
          <Typography variant="subtitle2" sx={{color: '#999'}}>{subTitle}</Typography>
        }
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  tile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e8e8e8',
    borderRadius: '4px',
    padding: '10px',
    cursor: 'pointer',
    minHeight: '150px',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
  },
  mobileTile: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #e8e8e8',
    borderRadius: '4px',
    padding: '10px',
    cursor: 'pointer',
    minHeight: '150px',
    boxSizing: 'border-box',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
  },
  image: {
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
  text: {
    textAlign: 'center',
    width: '100%',
    zIndex: 1,
  },
};

export default NavigationTile;
