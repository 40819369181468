import React, { useState } from "react";
import PageHeader from "../../shared/components/page/page-header/PageHeader";
import CasesList from './components/case-list/CasesList';
import { Box } from "@mui/material";
import { CasesService } from "../../shared/services/cases/casesService";
import EditCase from "./components/edit-case/EditCase";
import { Case } from "../../shared/models/case.model";
import { ConfirmationDialog } from "../../shared/components/page/popup-dialog/ConfirmationDialog";
import useAppStore from "../../appStore";
import { useTranslation } from "react-i18next";

const DepartmentCasesPage = () => {
    const { toggleMasterRefresh } = useAppStore();
    const {t} = useTranslation();
    const service = new CasesService();
    const [caseToEdit, setCaseToEdit] = useState<Case | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<Case | null>(null);

    const handleNewCase = () => {
        setCaseToEdit(new Case());
        setIsEditMode(true);
        setIsOpen(true);
    };

    const handleEditCase = (item: Case, edit = false) => {
        setCaseToEdit(item);
        setIsEditMode(edit);
        setIsOpen(true);
    };

    const handleDeleteConfirmed = async () => {
        if (!itemToDelete) return;

        try {
            await service.deleteCase(itemToDelete.id);
            setDeleteDialogOpen(false);
            toggleMasterRefresh();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteClicked = (item: Case) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    const handleCaseEditorAction = () => {
        setIsEditMode(false);
        setIsOpen(false);
        setCaseToEdit(null);
    };

    return (
      <div>
          {!isEditMode && (
            <>
                <PageHeader
                  title={t('cases')}
                  actionButtonName={t('new-case')}
                  onActionClicked={handleNewCase}
                />
                <Box mt={3} mb="120px">
                    <CasesList
                      onEdit={handleEditCase}
                    />
                </Box>
            </>
          )}
          {isOpen && caseToEdit && (
            <EditCase
              caseToEdit={caseToEdit}
              editDefault={isEditMode}
              setCaseToEdit={setCaseToEdit}
              open={isOpen}
              onActionClicked={handleCaseEditorAction}
            />
          )}
          {deleteDialogOpen && (
            <ConfirmationDialog
              isOpen={deleteDialogOpen}
              setIsOpen={setDeleteDialogOpen}
              message="Delete Case?"
              description="Are you sure you want to delete this case? This action cannot be undone."
              onConfirmClicked={handleDeleteConfirmed}
            />
          )}
      </div>
    );
};

export default DepartmentCasesPage;