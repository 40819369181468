import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Grid, Menu, MenuItem, IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange"; // Import an icon for the button
import useAppStore from "../../../../../appStore";
import { formatDate } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";

const JointInspectionTile = ({ stats, setFromDate, setToDate }) => {
  const { appImages } = useAppStore();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [preset, setPreset] = useState("");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePresetChange = (value) => {
    setPreset(value);
    setAnchorEl(null);

    if (value === "all") {
      setFromDate(undefined);
      setToDate(undefined);
    } else {
      setFromDate(DateTime.now().minus({ days: Number(value) }));
      setToDate(DateTime.now());
    }
  };

  return (
    <Card sx={{
      height: "100%",
      boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
      borderBottomLeftRadius: '0', borderBottomRightRadius: '0',
      border: '1px solid #dcdcdc',
      borderBottom: '1px dashed #999'
    }}>
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between" sx={{marginBottom: '25px'}}>
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            Joint Inspections
          </Typography>
          {/* Icon button that opens the dropdown menu */}
          <IconButton className={'me-1'} onClick={handleMenuOpen}>
            <DateRangeIcon sx={{fontSize: '1.9rem', color: '#c7c7c7'}}/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handlePresetChange("30")}>Last 30 Days</MenuItem>
            <MenuItem onClick={() => handlePresetChange("60")}>Last 60 Days</MenuItem>
            <MenuItem onClick={() => handlePresetChange("90")}>Last 90 Days</MenuItem>
            <MenuItem onClick={() => handlePresetChange("all")}>All time</MenuItem>
          </Menu>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: "center", justifyContent: "center" }}>
              <img src={appImages.iconPassed} style={{ width: "34px", height: "34px", marginTop: "1px" }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {stats.joint_inspection_score}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('average')}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
              <img src={appImages.iconType} style={{ width: "44px", height: "44px" }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {stats.inspected_spaces_count}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('spaces')}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <div style={{ height: "40px", alignItems: 'center', justifyContent: 'center' }}>
              <img src={appImages.iconInspectionAlt} style={{ width: "32px", height: "23px", marginTop: '9px' }} />
            </div>
            <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
              {stats.last_inspection_date ? formatDate(stats.last_inspection_date) : 'N/A'}
            </Typography>
            <Typography variant="body2" color="textSecondary">{t('last-inspection')}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default JointInspectionTile;
