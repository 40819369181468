import React, { useEffect, useState } from "react";
import { AppBar, Button, Dialog, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import useTracer from "../../../../shared/framework/tracer/tracer";
import EditInspectedEntity from "./components/edit-inspected-entity/EditInspectedEntity";
import useInspectionsStore from "../../inspectionsStore";
import { InspectionService } from "../../../../shared/services/inspections/inspectionService";
import InspectionSummary from "./components/inspection-summary/InspectionSummary";
import JointInspectedByCard from "./components/joint-inspection/JointInspectedByCard";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import InspectedEntitiesCardList from "./components/inspected-entities-list/mobile-view/InspectedEntitiesCardList";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import InspectionDatePicker from "./components/inspection-custom-date/InspectionDatePicker";
import useAppStore from "../../../../appStore";
import SageInspectModal from "./components/create-inspected-entity/AssistedInspectionModal";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";
import DivToPdf from "./components/inspection-pdf-print/DivToPdf";
import { printStyles } from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import InspectedByCard from "./components/inspection-pdf-print/InspectedByCard";
import Box from "@mui/material/Box";


const EditAreaInspectionPage = (props) => {
    const {
        onDelete,
        state,
        setState,
    } = props;

    const tracer = useTracer();

    const { inspection,
        setInspection,
        newInspectedEntity,
        setInspectedEntity,
        setBuilding,
        setFloor,
    } = useInspectionsStore();

    const {loggedInUser, toggleMasterRefresh, masterRefresh} = useAppStore()
    const dateChangePermission = loggedInUser?.permissions?.hasAccess( 'Change Inspection Date') || false;
    const [isAddInspectionOpen, setIsAddInspectionOpen] = useState(false);
    const [isEditInspectionOpen, setIsEditInspectionOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [totalSpaces, setTotalSpaces] = useState<number>(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));


    useEffect(() => {
        if (state.inspectionToEdit === null) {
            return;
        }
        const getInspectionDetails = async () => {
            setIsLoading(true);
            try {
                const service = new InspectionService();
                let newInspection = await service.getInspectionDetails(state.inspectionToEdit.id);
                setInspection(newInspection);
                newInspectedEntity();
            } catch (error) {
                tracer.trace('Inspections', 'Error', `Error: ${error}`);
                if (error instanceof Error) {
                    console.log(error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (state.inspectionToEdit.id !== null) {
            tracer.trace('Inspections', 'Trace', 'Getting inspection details');
            setIsEditing(false);
            getInspectionDetails().then();
        } else {
            tracer.trace('Inspections', 'Trace', 'Creating new Inspection');
            setInspection(state.inspectionToEdit);
            newInspectedEntity();
            setIsEditing(true);
        }
    }, [state.inspectionToEdit]);



    const handleClose = () => {
        setIsAddInspectionOpen(false)
        setState(prevState => ({ ...prevState, open: false, inspectionToEdit: null }));
    }

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    }

    const handleSave = async () => {
        try {
            const service = new InspectionService();
            await service.updateInspection(inspection);
            const updatedInspection = await service.getInspectionDetails(inspection.id);
            setInspection(updatedInspection);
            console.log('Inspection updated successfully.');
            toggleMasterRefresh();
            setIsAddInspectionOpen(false);
            handleClose();
        } catch (error) {
            console.error('Error saving inspection:', error);
        }
    };


    const handleInspectClicked = () => {
        newInspectedEntity()
        setBuilding(null)
        setFloor(null)
        setIsAddInspectionOpen(true)
    }

    const handleDeleteClicked = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        handleClose();
        onDelete(state.inspectionToEdit);
    }

    const handleOnActionClicked = () => {
        setIsAddInspectionOpen(false)
    }

    const handleEditInspectedEntityClicked = (entity) => {
        setInspectedEntity(entity)
        setIsEditInspectionOpen(true)
    }



    if (isLoading) {
        return <CustomLoadingDots />;
    }

    return (
      <>
          <>
              <style>{printStyles}</style>
              <Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={ModalTransition}
                      PaperProps={{
                          style: {
                              overflowX: 'hidden',
                              overflowY: 'auto',
                          },
                      }}>
                  <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
                      <Toolbar>
                          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                              <CloseIcon />
                          </IconButton>

                          {/* Spacer to push the following buttons to the right */}
                          <Box sx={{ flexGrow: 1 }} />

                          {!isMobile && !isEditing && (
                            <DivToPdf contentId={"modal-content"} />
                          )}
                          {!isEditing && (
                            <Button
                              color="inherit"
                              variant="contained"
                              onClick={toggleEditMode}
                              sx={{ color: '#444444', mr: '3px' }}
                            >
                                {"Edit"}
                            </Button>
                          )}
                          {onDelete && inspection.id !== 0 && !isEditing && (
                            <Button
                              color="inherit"
                              variant="contained"
                              onClick={handleDeleteClicked}
                              sx={{ color: '#444444' }}
                            >
                                {"Delete"}
                            </Button>
                          )}
                      </Toolbar>
                  </AppBar>


                  <SageInspectModal open={isAddInspectionOpen} onActionClicked={handleOnActionClicked} />
                  <EditInspectedEntity open={isEditInspectionOpen} onActionClicked={() => setIsEditInspectionOpen(false)} />

                   Main Modal Content Wrapper
                  <div id="modal-content" className="d-flex flex-column flex-column-fluid mx-5"
                       style={{ height: "calc(100vh - 100px)", marginTop: '80px' }}>
                      <InspectionSummary totalSpaces={totalSpaces} setTotalSpaces={setTotalSpaces} />
                      <div style={{ marginBottom: dateChangePermission ? "5px" : "20px" }}>
                          {isEditing ? (
                            <JointInspectedByCard />
                          ) : (
                            <InspectedByCard />
                          )}
                      </div>
                      {dateChangePermission &&
                        <div style={{ marginBottom: "20px" }}>
                            <InspectionDatePicker isEditing={isEditing} />
                        </div>
                      }
                      <div style={{paddingBottom: '50px'}}>
                          <InspectedEntitiesCardList
                            onEditInspectedEntityClicked={handleEditInspectedEntityClicked}
                            onAddInspectionsClick={handleInspectClicked}
                            isEditing={isEditing}
                          />
                      </div>
                  </div>

                  <ConfirmationDialog
                    isOpen={isConfirmDialogOpen}
                    setIsOpen={setIsConfirmDialogOpen}
                    message="Confirm Deletion"
                    description="Are you sure you want to delete this inspection? This action cannot be undone."
                    onConfirmClicked={handleConfirmDelete}
                  />
                  {isEditing &&
                    <ModalFooterActions onClose={handleClose} onSave={handleSave} />
                  }
              </Dialog>
          </>

      </>
    );
}

export default EditAreaInspectionPage;


