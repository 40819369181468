import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField
} from "@mui/material";
import { Media } from "../../../../../../shared/models/media.model";
import PhotoUploaderViewer from "../../../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import DatePicker from "../../../../../../shared/components/forms/date-time/date-picker/DatePickerButton";
import { useTranslation } from "react-i18next";



const WorkItemForm = (props) => {

  const {
    workItemToEdit,
    setWorkItemToEdit,
    isEditing
  } = props

  const isClosed = workItemToEdit.status === 'Closed';
  const [media, setMedia] = useState<Media[]>(workItemToEdit.media);
  const {t} = useTranslation();

  const handleDateChange = (newValue) => {
    setWorkItemToEdit(prevWorkItem => ({ ...prevWorkItem, due_date: newValue, is_overdue: false }));
  };

  useEffect(() => {
    if (workItemToEdit.status !== "Closed") {
      setWorkItemToEdit(prevWorkItem => ({
        ...prevWorkItem,
        status: prevWorkItem.assigned_to_employees.length === 0 ? "Pending" : "In Progress"
      }));
    }
  }, [workItemToEdit.assigned_to_employees]);


  useEffect(() => {
    setWorkItemToEdit(prevWorkItem => {
      const updatedWorkItem = {
        ...prevWorkItem,
        media: media
      };
      console.log(updatedWorkItem)
      return updatedWorkItem;
    });

  }, [media]);

  const handleDescChange = (event) => {
    setWorkItemToEdit(prevWorkItem => ({ ...prevWorkItem, description: event.target.value }));
  };

  const handleCloseCheckbox = (event) => {
    setWorkItemToEdit(prevWorkItem => {
      if (!prevWorkItem.status || prevWorkItem.status !== 'Closed') {
        return { ...prevWorkItem, status: 'Closed' };
      } else {
        return {
          ...prevWorkItem,
          status: prevWorkItem.assigned_to_employees.length === 0 ? "Pending" : "In Progress"
        };
      }
    });
  };

  const handleGoodCatchCheckbox = (event) => {
    setWorkItemToEdit(prevWorkItem => {
      return {
        ...prevWorkItem,
        is_good_catch: !prevWorkItem.is_good_catch
      };
    });
  };


  return (

    <div className="flex-column-fluid me-10">
      <div style={{marginBottom: '15px'}}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-gray-700">{t('details')}</span>
        </h3>
      </div>
      <Box sx={{
        display: 'flex',
        p: 0.5,
        marginTop: 1,
        width: '100%',
      }}>
        {workItemToEdit.id !== 0 &&
          <FormControlLabel
            control={
              <Checkbox
                checked={isClosed}
                onChange={handleCloseCheckbox}
                name="closedCheckbox"
                color="primary"
              />
            }
            label="Closed"
          />
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={workItemToEdit.is_good_catch}
              onChange={handleGoodCatchCheckbox}
              name="goodCatchCheckbox"
              color="primary"
            />
          }
          label={t('good-catch')}
        />
      </Box>
      <DatePicker workItemToEdit={workItemToEdit} onDateChange={handleDateChange}/>
      <div style={{ marginTop: 15 }}>
        <PhotoUploaderViewer
          item={workItemToEdit}
          setMedia={setMedia}
          captions={workItemToEdit.status === 'Closed'}
          module={'workitems'}
        />
      </div>
      <TextField
        label={t('additional-notes')}
        name='Description'
        value={workItemToEdit.description}
        onChange={handleDescChange}
        fullWidth
        multiline
        rows={6}
        style={{marginTop: -15}}
      />

    </div>
  );

}

export default WorkItemForm
