
import { CommandGetSageResponse } from "./commands/cmdGetSageReponse";

export class SageChatService {

  async getResponse(message) {
    const cmd = new CommandGetSageResponse();
    const result = cmd.run(message)
    return result
  }

}
