import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../../../../../../../../_metronic/helpers";
import useAppStore from "../../../../../../../../../../appStore";


const SelectableTile = (props) => {

    const {
      disabled,
      data,
      selectedIndex,
      onTileClicked,
      icon='plus',
      sageModal=false
    } = props;

    const {whiteLabelProps} = useAppStore()
    const [isSelected, setIsSelected] = useState(false)
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  useEffect(() => {

        if (data.id && selectedIndex === data.id )
          setIsSelected(true)
        else
          setIsSelected(false)

    }, [selectedIndex])

    const handleDivClick = (data) => {
      onTileClicked(data)
    }

    return (

      <Grid item
            xs={3.7}
            sm={sageModal ? 3.7 : 3}
            md={sageModal ? 3.7 : 2}
            key={data}
            onClick={() => handleDivClick(data)}
            style={{
              border: 'solid 1px #c4c4c4',
              borderRadius: '4px',
              backgroundColor: isSelected ? (disabled ? '#dcdcdc' : whiteLabelProps.primary_color) : 'white',
              overflow: 'hidden',
              width: isMobile ? '33%' : '100px',
              margin: '2px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center', // centers vertically in flexbox
              alignItems: 'center', // centers horizontally in flexbox
            }}
      >
        <div style={{textAlign: 'center'}}>
          <KTIcon iconName={icon} className={isSelected ? 'fs-2 me-1 text-light' : 'fs-2 me-1'}/>
          <Typography variant="body1"
                      style={{
                        color: isSelected ? (disabled ? 'grey' : whiteLabelProps.contrast_color) : 'black',
                        textAlign: 'center',
                        cursor: 'default',
                      }}
          >
            {data.name}
          </Typography>
        </div>
      </Grid>



    )
}

export default SelectableTile