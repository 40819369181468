import React, { useState } from "react";
import useInspectionsStore from "../../../../../inspectionsStore";
import SubHeader from "../../../../../../../shared/components/page/page-header/SubHeader";
import InspectedEntityCard from "./InspectedEntityCard";
import { Typography } from "@mui/material";
import { ConfirmationDialog } from "../../../../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import useAppStore from "../../../../../../../appStore";
import { InspectionService } from "../../../../../../../shared/services/inspections/inspectionService";
import { InspectedEntity } from "../../../../../../../shared/models/inspectedEntity.model";
import { useTranslation } from "react-i18next";

const InspectedEntitiesCardList = (props) => {

  const {
    onAddInspectionsClick,
    onEditInspectedEntityClicked,
    onDeleteInspectedEntityClicked,
    isEditing,
  } = props;

  const {inspection} = useInspectionsStore();
  const {t} = useTranslation();
  const {toggleMasterRefresh} = useAppStore();
  const [deleting, setDeleting] = useState(false);
  const service = new InspectionService();
  const [ieToEdit, setIeToEdit] = useState<InspectedEntity | null>(null);

  const handleDeleteConfirmed = async () => {
    if (!ieToEdit) return;
    try {
      await service.deleteInspectedEntity(inspection.id, ieToEdit.id);
      inspection.inspected_entities = inspection.inspected_entities.filter(entity => entity.id !== ieToEdit.id);
      setDeleting(false);
      toggleMasterRefresh();
    } catch (error) {
      console.error(error);
    }
  }


  const handleDeleteClicked = (entity) => {
    setIeToEdit(entity)
    setDeleting(true);
  }

  return (
    <>
      <div style={{ marginBottom: isEditing ? '0' : '20px'}}>
        <SubHeader
          title={t('inspected-spaces')}
          subtitle={null}
          showAction={isEditing ? true : false}
          actionButtonName={`${t('inspect')}...`}
          onActionClicked={onAddInspectionsClick}
        />
      </div>
      <div style={{marginBottom: '60px'}}>
        {inspection.inspected_entities.length > 0 ? inspection.inspected_entities.map((space) =>
          space.id !== null ? (
            <InspectedEntityCard
              key={space.id}
              onEdit={onEditInspectedEntityClicked}
              onDelete={handleDeleteClicked}
              inspected_entity={space}
              isEditing={isEditing}
            />
          ) : null
        ) : <Typography sx={{textAlign: 'center', marginTop: '25px'}}>No spaces inspected yet</Typography>}
      </div>
      {deleting && (
      <ConfirmationDialog
        isOpen={deleting}
        setIsOpen={setDeleting}
        message="Delete Inspected Entity?"
        description={`Are you sure you want to delete this inspected entity? This action cannot be undone.`}
        onConfirmClicked={handleDeleteConfirmed}
      />
    )}

    </>
  );
}

export default InspectedEntitiesCardList