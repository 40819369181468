// casesStore.ts
import { create } from 'zustand';
import { Case } from '../../shared/models/case.model';

interface Field {
  type: string; // Define types as needed
  name: string;
}

interface Section {
  name: string;
  sage_prompt: string;
  fields: Field[];
}

interface CasesStore {
  sageResponse: string;
  initialSteps: number;
  endingSteps: number;
  setSageResponse: (response: string) => void;
  caseToEdit: Case;
  setCaseToEdit: (caseToEdit: Case | ((prevCase: Case) => Case)) => void;
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
  activeSection: number;
  setActiveSection: (activeSection: number) => void;
  sections: Section[];
  setSections: (sections: Section[]) => void;
  reset: () => void;
}

const useCasesStore = create<CasesStore>((set, get) => ({
  sageResponse: '',
  initialSteps: 4,
  endingSteps: 2,
  caseToEdit: new Case(),
  isTyping: true,
  activeSection: 0,
  sections: [],
  setSageResponse: (sageResponse) => set({ sageResponse }),
  setCaseToEdit: (update) =>
    set((state) => ({
      caseToEdit: typeof update === 'function' ? update(state.caseToEdit) : update,
    })),  setIsTyping: (isTyping) => set({ isTyping }),
  setActiveSection: (activeSection) => set({ activeSection }),
  setSections: (sections) => set({ sections }),
  reset: () =>
    set({
      sageResponse: '',
      caseToEdit: new Case(),
      isTyping: true,
      activeSection: 0,
      sections: [],
    }),
}));

export default useCasesStore;
