import { BaseSerializer } from "./baseSerializer";
import { CaseForm } from "../caseForm.model";
import { DynamicFormSerializer } from "./dynamicFormSerializer";

export class CaseFormSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let form = new CaseForm();

    this._copyAttributes(form, data);

    let formSerializer = new DynamicFormSerializer();
    data.form = formSerializer.deserialize(data.form);

    return form;

  }

}