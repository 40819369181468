import { CommandGetInspections } from "./commands/cmdGetInspections"
import { CommandCreateInspection } from "./commands/cmdCreateInspection";
import { CommandUpdateInspection } from "./commands/cmdUpdateInspection";
import { CommandCreateInspectedEntity } from "./commands/cmdCreateInspectedEntity";
import { CommandGetInspectedEntities } from "./commands/cmdGetInspectedEntities";
import { CommandGetInspectionDetails } from "./commands/cmdGetInspectionDetails";
import { CommandUpdateInspectedEntity } from "./commands/cmdUpdateInspectedEntity";
import { CommandGetInspectionSummary } from "./commands/cmdGetInspectionSummary";
import { CommandDeleteInspection } from "./commands/cmdDeleteInspection";
import { CommandDeleteInspectedEntity } from "./commands/cmdDeleteInspectedEntity";


export class InspectionService {

    async getInspections(provider_id, location_id, offset=0, limit=Number.MAX_SAFE_INTEGER, search=null, employee=null, last_x_days=null) {
        const cmd = new CommandGetInspections();
        const results = cmd.run(provider_id, location_id, offset, limit, search, employee, last_x_days)
        return results
    }

    async createInspection(inspection) {
        const cmd = new CommandCreateInspection()
        const results = cmd.run(inspection)
        return results
    }

    async getInspectionDetails(inspection_id) {
        const cmd = new CommandGetInspectionDetails()
        const results = cmd.run(inspection_id)
        return results
    }

    async updateInspection(inspection) {
        const cmd = new CommandUpdateInspection()
        const results = cmd.run(inspection)
        return results
    }

    async getInspectedEntities(provider_id, location_id, offset=0, limit=10, search=null) {
        const cmd = new CommandGetInspectedEntities()
        const results = cmd.run(provider_id, location_id)
        return results
    }

    async createInspectedEntity(inspection, inspectedEntity) {
        const cmd = new CommandCreateInspectedEntity()
        const results = cmd.run(inspection, inspectedEntity)
        return results
    }

    async updateInspectedEntity(inspection, inspectedEntity) {
        const cmd = new CommandUpdateInspectedEntity()
        const results = cmd.run(inspection, inspectedEntity)
        return results
    }

    async getInspectionSummary(provider_id, location_id) {
        const cmd = new CommandGetInspectionSummary()
        const results = cmd.run(provider_id, location_id)
        return results
    }

    async deleteInspection(inspection_id) {
        const cmd = new CommandDeleteInspection()
        const result = cmd.run(inspection_id)
        return result
    }

    async deleteInspectedEntity(inspection_id, entity_id) {
        const cmd = new CommandDeleteInspectedEntity()
        const result = cmd.run(inspection_id, entity_id)
        return result
    }


}