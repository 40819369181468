import { BaseSerializer } from "./baseSerializer";
import { Section } from "../caseForm.model";

export class SectionSerializer extends BaseSerializer {

  public deserialize( data ): any {

    let section = new Section();

    this._copyAttributes(section, data);

    return section;

  }

}