import React, { createContext, useContext, useState, ReactNode } from "react";
import { WorkItem } from "../../../shared/models/workItem.model";

interface WorkItemContextType {
  workItems: WorkItem[];
  setWorkItems: React.Dispatch<React.SetStateAction<WorkItem[]>>;
  workItemToEdit: WorkItem;
  setWorkItemToEdit: React.Dispatch<React.SetStateAction<WorkItem>>;
  isEditing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closingDialogOpen: boolean;
  setClosingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  snackbarOpen: boolean;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  statusCounts: { pending_count: number; assigned_count: number; closed_count: number };
  setStatusCounts: React.Dispatch<React.SetStateAction<{ pending_count: number; assigned_count: number; closed_count: number }>>;
}

const WorkItemContext = createContext<WorkItemContextType | undefined>(undefined);

export const useWorkItem = () => {
  const context = useContext(WorkItemContext);
  if (context === undefined) {
    throw new Error("useWorkItem must be used within a WorkItemProvider");
  }
  return context;
};

interface WorkItemProviderProps {
  children: ReactNode;
}

export const WorkItemProvider: React.FC<WorkItemProviderProps> = ({ children }) => {
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [workItemToEdit, setWorkItemToEdit] = useState<WorkItem>(new WorkItem());
  const [isEditing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [closingDialogOpen, setClosingDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sortBy, setSortBy] = useState('create_date');
  const [statusCounts, setStatusCounts] = useState({ pending_count: 0, assigned_count: 0, closed_count: 0 });

  const value = {
    workItems,
    setWorkItems,
    workItemToEdit,
    setWorkItemToEdit,
    isEditing,
    setEditing,
    open,
    setOpen,
    closingDialogOpen,
    setClosingDialogOpen,
    snackbarOpen,
    setSnackbarOpen,
    sortBy,
    setSortBy,
    statusCounts,
    setStatusCounts
  };

  return <WorkItemContext.Provider value={value}>{children}</WorkItemContext.Provider>;
};
